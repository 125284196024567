import { MilitaryService } from "../../../../core/types/provider";
import { FieldResponse, Props } from "../provider-fields";

export const military_service = ({
  payload,
}: Props<MilitaryService>): FieldResponse[] => [
  {
    label: "Served or Serving",
    value: payload["served/serving"],
  },
  {
    label: "Branch",
    value: payload.branch,
  },
  {
    label: "Current Service",
    value: payload.current_service,
  },
  {
    label: "Last Location",
    value: payload.last_location,
  },
  {
    label: "Start Date",
    value: payload.start_date,
  },
  {
    label: "End Date",
    value: payload.end_date,
  },
  {
    label: "Type of Discharge",
    value: payload.type_of_discharge,
  },
];
