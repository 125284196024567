import { Card, Carousel, Col, Row } from "antd";
import { ProviderInfo } from "../types/Provider";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { safeAccess } from "../../../core/utils/safe-access.utils";
import { PracticeLocation } from "../../../core/types/provider";
import { FormField } from "../../../components/FormField";

export const ProviderPracticeLocations = ({ provider }: ProviderInfo) => {
  const Address = (practice: PracticeLocation) => {
    const address = safeAccess(
      practice?.location,
      ({ general_information }) =>
        general_information[0].contact_information.physical_address[0]
    );
    return (
      <div className="flex flex-row items-center gap-x-3">
        <EnvironmentOutlined className="text-xl" />
        <div className="flex flex-col">
          <span>
            {address?.street.join(", ")} {address?.city}, {address?.state},{" "}
            {address?.zip}
          </span>
        </div>
      </div>
    );
  };

  const Phone = (practice: PracticeLocation) => {
    const phone = safeAccess(
      practice?.location,
      ({ general_information }) =>
        general_information[0].contact_information.phone[0].phone[0].number
    );

    return (
      <div className="flex flex-row items-center gap-x-3">
        <PhoneOutlined className="text-xl" />
        {phone}
      </div>
    );
  };

  const Fax = (practice: PracticeLocation) => {
    const fax = safeAccess(
      practice?.location,
      ({ general_information }) =>
        general_information[0].contact_information.fax[0].fax[0]
    );

    return (
      <div className="flex flex-row items-center gap-x-3">
        <PrinterOutlined className="text-xl" />
        {fax}
      </div>
    );
  };

  const AcceptingPatients = (practice: PracticeLocation) => {
    const accepting = safeAccess(
      practice,
      ({ general_information }) => general_information.practice_at_location
    );

    return (
      <div className="flex flex-row items-center gap-x-3">
        {accepting ? (
          <CheckCircleOutlined className="text-xl text-green-600" />
        ) : (
          <CloseCircleOutlined className="text-xl text-red-600" />
        )}
        <span>
          {accepting
            ? "Accepting new patients at this location"
            : "Does not accept patients at this location"}
        </span>
      </div>
    );
  };

  return (
    <Card
      className="shadow-sm"
      title={<span className="text-sky-500">Locations</span>}
    >
      <Carousel>
        {provider?.practice_locations.locations.map(
          (practiceLocation, index) => (
            <Card
              key={index}
              title={
                <span className="text-sky-500">
                  {safeAccess(
                    practiceLocation?.location,
                    ({ general_information }) =>
                      general_information[0].practice_name
                  ) ?? "N/A"}
                </span>
              }
            >
              <div className="flex flex-col w-full gap-y-12">
                <Row gutter={20} justify="space-between" className="w-full">
                  <Col span={6}>
                    <Address {...practiceLocation} />
                  </Col>
                  <Col span={6}>
                    <Phone {...practiceLocation} />
                  </Col>
                  <Col span={6}>
                    <Fax {...practiceLocation} />
                  </Col>
                  <Col span={6}>
                    <AcceptingPatients {...practiceLocation} />
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <FormField
                      label="Specialty"
                      value={
                        safeAccess(
                          practiceLocation,
                          ({ general_information }) =>
                            general_information.specialty
                        ) ?? "N/A"
                      }
                    />
                  </Col>
                  <Col span={6}>
                    <FormField label="Provider type" value={"N/A"} />
                  </Col>
                  <Col span={6}>
                    <FormField label="In network" value={"N/A"} />
                  </Col>
                  <Col span={6}>
                    <FormField label="Services" value={"N/A"} />
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <FormField
                      label="Tin:"
                      layout="horizontal"
                      value={
                        safeAccess(
                          practiceLocation?.location,
                          ({ tax_information }) => tax_information.tax_id
                        ) ?? "N/A"
                      }
                    />
                  </Col>
                  <Col span={6}>
                    <FormField
                      label="Npi:"
                      layout="horizontal"
                      value={
                        safeAccess(
                          practiceLocation?.location,
                          ({ general_information }) =>
                            general_information[0].NPI_type2
                        ) ?? "N/A"
                      }
                    />
                  </Col>
                </Row>
              </div>
            </Card>
          )
        )}
      </Carousel>
    </Card>
  );
};
