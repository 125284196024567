import { ProviderDataModel } from "../../../core/types/provider";
import { ProviderDataForm } from "../components/ProviderDataForm";
import { RenderProviderDataFormType } from "../types/Provider";

export const renderProviderDataForm =
  (onSelectField: (val: string) => void) =>
  ({ path, label, value }: RenderProviderDataFormType) =>
    (
      <ProviderDataForm
        key={path}
        path={path}
        label={label}
        value={value}
        onSelectField={onSelectField}
      />
    );

export const renderColumns = (provider: ProviderDataModel | undefined) => {
  return [
    {
      title: "SPECIALTY",
      row: provider?.specialty.specialties[0].name,
    },
    {
      title: "NETWORKS",
    },
    {
      title: "TIN",
    },
    {
      title: "CONTRACTS",
    },
  ];
};
