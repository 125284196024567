import { Space, Spin, Typography } from "antd";
import { useParams } from "react-router-dom";
import { ProviderDataPreview } from "./ProviderDataPreview";
import { useReactQuery } from "../../core/hooks/useReactQuery";
import { ProviderDataModel } from "../../core/types/provider";

const ProviderPage = () => {
  const { id } = useParams();

  const {
    data: provider,
    isLoading: loading,
    refetch,
  } = useReactQuery<ProviderDataModel>({
    queryKey: [`provider_${id}`],
    url: `/providers/${id}`,
  });

  if (!id || loading) {
    return (
      <div className="min-h-[75vh] flex items-center justify-center">
        <Space className="items-center" direction="vertical">
          <Spin size="large" />
          <Typography>Loading provider data ...</Typography>
        </Space>
      </div>
    );
  }

  return <ProviderDataPreview provider={provider} refetch={refetch} />;
};

export default ProviderPage;
