import { ProviderDataModel } from "./provider";
import { ValidationWorkflow } from "./workflow";

export interface LoginProps {
  email: string;
  password: string;
}

export enum USER_ROLE {
  ADMIN = "admin",
  PROVIDER = "provider",
  PAYER = "payer",
  SUPPORT = "support",
}

export enum USER_STATUS {
  ACTIVE = "active",
  ARCHIVED = "archived",
  CLOSED = "closed",
}

export enum USER_REGISTRATION_TYPE {
  // account created automatically via uploaded eq. caqh file
  FILE = "file",
  // account created automatically by system
  SYSTEM = "system",
  // account create by admin in admin panel
  ADMIN = "admin",
  // account created manually with registration template in UI
  MANUAL = "manually",
}

export type User = {
  _id: string;
  firstName: string;
  lastName: string;

  roles: string[];
  status: USER_STATUS;
  cognitoGuid?: string;

  contact: {
    email: string;
    phone: string;
  };

  properties: {
    isPasswordUpdated: boolean;
    isRegistrationConfirmed: boolean;
    registrationType: USER_REGISTRATION_TYPE;
  };
};

export type Provider = User & {
  npi: string;
  caqh: ProviderDataModel;
  validation: {
    workflow: ValidationWorkflow;
  };
};

export enum AuthStatusType {
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
  CHALLENGE_REQUIRED = "CHALLENGE_REQUIRED",

  ADMIN_NO_SRP_AUTH = "ADMIN_NO_SRP_AUTH",
  CUSTOM_CHALLENGE = "CUSTOM_CHALLENGE",
  DEVICE_PASSWORD_VERIFIER = "DEVICE_PASSWORD_VERIFIER",
  DEVICE_SRP_AUTH = "DEVICE_SRP_AUTH",
  MFA_SETUP = "MFA_SETUP",
  NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED",
  PASSWORD_VERIFIER = "PASSWORD_VERIFIER",
  SELECT_MFA_TYPE = "SELECT_MFA_TYPE",
  SMS_MFA = "SMS_MFA",
  SOFTWARE_TOKEN_MFA = "SOFTWARE_TOKEN_MFA",
}

export type AuthResponse = {
  status: AuthStatusType;
  message?: string;
  result: Record<string, string>;
};
