import { Table, Tooltip } from "antd";
import { getTitleFromKey } from "../utils";
import { Change, Roster } from "../../../core/types/ingestion-process";
import {
  MinusCircleFilled,
  PlusCircleFilled,
  SwapOutlined,
} from "@ant-design/icons";
import { calculateTotalDiscrepancies } from "../start-process/components/utils";

export enum ChangeType {
  ADD,
  CHANGE,
  TERMS,
}

export type PreviewDiscrepancyType = {
  type: ChangeType;
} & Change;

const getChangesCount = (roster: Roster) => {
  const counts = calculateTotalDiscrepancies([roster]);
  return {
    adds: counts.locationsAdds + counts.providersAdds,
    changes: counts.locationsChanges + counts.providersChanges,
  };
};

export const ROSTERS_CHANGES_TABLE_COLUMNS = ({ page }: { page: number }) => [
  Table.EXPAND_COLUMN,
  {
    title: "",
    key: "index",
    render: (_: any, __: any, index: number) => (page - 1) * 10 + (index + 1),
  },
  {
    title: "First name",
    dataIndex: [
      "payload",
      "provider",
      "content",
      "personal_information",
      "name",
      "0",
      "first",
    ],
  },
  {
    title: "Last name",
    dataIndex: [
      "payload",
      "provider",
      "content",
      "personal_information",
      "name",
      "0",
      "last",
    ],
  },
  {
    title: "NPI",
    dataIndex: [
      "payload",
      "provider",
      "content",
      "personal_information",
      "npi",
      "0",
      "npi",
    ],
  },
  {
    title: "Adds",
    render: (roster: Roster) => getChangesCount(roster).adds,
  },
  {
    title: "Changes",
    render: (roster: Roster) => getChangesCount(roster).changes,
  },
];

export const ADD_COLOR = "#15803E";
export const CHANGE_COLOR = "#9039EB";
export const DELETE_COLOR = "#B91C1C";

const changeIcon = (change: Change) => {
  if (!change.newValue) {
    return <MinusCircleFilled className="text-red-600" />;
  } else if (!change.prevValue && change.newValue) {
    return <PlusCircleFilled className="text-green-600" />;
  } else if (change.prevValue && change.newValue) {
    return <SwapOutlined className="text-yellow-600" />;
  }
};

export const ROSTER_CHANGES_COLUMNS = [
  {
    render: (record: Change) => changeIcon(record),
  },
  {
    title: "Field",
    dataIndex: "path",
    render: (value: string) => (
      <Tooltip title={value}>{getTitleFromKey(value)}</Tooltip>
    ),
  },
  {
    title: "Previous value",
    dataIndex: "prevValue",
  },
  {
    title: "New value",
    dataIndex: "newValue",
    className: "font-semibold",
  },
];
