import { Alert, Form, Input, InputNumber, Modal, Select, Space, message } from "antd";
import { FC, useState } from "react";
import api from "../../../core/libs/api";
import { USER_ROLE } from "../../../core/types/auth";
import { mapRoleName } from "../utils";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

interface FormProps {
  firstName: string;
  lastName: string;
  password: string;
  email: string;
  phone?: string;
  role: string;
  npi?: string;
}

export const AddAccountModal: FC<Props> = ({ isOpen, onClose }) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);

  const [selectedRole, setSelectedRole] = useState<USER_ROLE>();
  const isProvider = selectedRole === USER_ROLE.PROVIDER;

  const onFinish = async ({ role, ...rest }: FormProps) => {
    setLoading(true);

    if (role === USER_ROLE.PROVIDER && !rest.npi) {
      message.error("NPI is required!");
      return;
    }

    await api
      .post("/users", {
        ...rest,
        roles: [role],
      })
      .then(() => {
        message.info("New user created!");
        onCancel();
      })
      .catch((err) => {
        // message.error(err?.message ?? "Unexpected error");
        setErrors(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submitForm = () => form.submit();

  const onCancel = () => {
    form.resetFields();
    setErrors([]);
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onCancel}
      onOk={submitForm}
      confirmLoading={loading}
      title="Create new account"
    >
      <Form
        className="pt-12"
        layout="vertical"
        form={form}
        name="createNewAccount"
        onFinish={onFinish}
      >
        <Form.Item label="Role" name="role" rules={[{ required: true }]}>
          <Select
            placeholder="Select role"
            onSelect={(role) => setSelectedRole(role)}
          >
            {[USER_ROLE.ADMIN, USER_ROLE.SUPPORT].map((role, index) => (
              <Select.Option key={index} value={role}>
                {mapRoleName[role]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {isProvider && (
          <Form.Item
            label="NPI number"
            name="npi"
            rules={[{ required: isProvider }]}
          >
            <InputNumber maxLength={10} className="min-w-full" />
          </Form.Item>
        )}

        <Form.Item
          label="First name"
          name="firstName"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Last name"
          name="lastName"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Email" name="email" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item label="Phone" name="phone" rules={[{ required: false }]}>
          <Input />
        </Form.Item>
      </Form>
      <Space direction="vertical" className="w-full">
        {errors &&
          Array.isArray(errors) &&
          errors?.map((err, index) => (
            <Alert key={index} message={err} type="error" showIcon />
          ))}
      </Space>
    </Modal>
  );
};
