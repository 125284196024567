export const MappingInformations = () => {
  return (
    <ul>
      <li>NPI number, First and Last Name are required for each provider.</li>
      <li>The TIN number is required for each location.</li>
      <li>
        For <a href="https://en.wikipedia.org/wiki/Boolean">boolean</a> values ,
        acceptable values passed in the roster are:
        <ul>
          <li>"Y, YES, Yes, TRUE, True, true" - for true value.</li>
          <li>"N, NO, No, FALSE, False, false" - for false value.</li>
        </ul>
      </li>
      <li>
        Each field should be passed in the appropriate data type for each field.
      </li>
      <li>
        Informations specific to a particular provider working in a location
        should be set with the fields Locations &gt; Provider General
        Information.
      </li>
    </ul>
  );
};
