import {
  CheckCircleFilled,
  CheckOutlined,
  CloseOutlined,
  FastForwardOutlined,
  HomeFilled,
  LoadingOutlined,
  PauseOutlined,
  WarningFilled,
  WarningOutlined,
} from "@ant-design/icons";
import {
  ApprovalStatus,
  IngestionProcess,
  ProcessStatus,
  ProcessStep,
  Roster,
  RosterStatus,
  StepStatus,
} from "../../core/types/ingestion-process";
import { Table, Tag } from "antd";

import dayjs from "dayjs";
import { ErrorPopoverList } from "./components/ErrorPopoverList";

export const mapStepName: Record<ProcessStep, string> = {
  [ProcessStep.FILE_UPLOAD]: "Starting",
  [ProcessStep.FILE_VALIDATION]: "Validating file structure",
  [ProcessStep.ADDRESS_NORMALIZATION]: "Performing address standarization",
  [ProcessStep.NPI_VALIDATION]: "Performing NPI validation",
  [ProcessStep.RECORD_VALIDATION]: "Performing record validation",
  [ProcessStep.DETECTING_CHANGES]: "Detecting adds, changes and terms",
  [ProcessStep.WAITING_ON_APPROVAL]: "Pending approval",
  [ProcessStep.APPLYING_CHANGES]: "Applying adds, changes and terms",
  [ProcessStep.FINISHED]: "Finished",
};

export const mapStatusIcon: Record<StepStatus, JSX.Element> = {
  [StepStatus.IN_PROGRESS]: <LoadingOutlined />,
  [StepStatus.NOT_STARTED]: (
    <PauseOutlined className="p-1.5 rounded bg-gray-200 text-black" />
  ),
  [StepStatus.FINISHED_SUCCESS]: (
    <CheckOutlined className="p-1.5 rounded bg-green-500 text-white" />
  ),
  [StepStatus.FINISHED_ERROR]: (
    <WarningOutlined className="p-1.5 rounded bg-red-500 text-white" />
  ),
  [StepStatus.SKIPPED]: (
    <FastForwardOutlined className="p-1.5 rounded bg-yellow-500 text-white" />
  ),
};

export const getTitleFromKey = (value: string = "") => {
  // prettier-ignore
  const customMapping: Record<string, string> = {
    "professional_identification_numbers.caqh.0.id": "CAQH",
    "personal_information.ssn.0.number": "SSN",
    "specialty.specialties.0.name": "Specialty name",
    "office_manager.0.email": "Office manager email",
    "general_information.0.contact_information.phone.0.number	": "Phone number",
    "provider.professional_identification_numbers.usmle.0.number":"USMLE number",
  };

  const custom = customMapping[value];
  if (custom) {
    return custom;
  }

  const parts = value.split(".");
  let lastPart = parts[parts.length - 1];

  // when the last part is an index, then get one previous index
  if (lastPart === "[0]" || lastPart === "[1]") {
    lastPart = parts[parts.length - 2];
  }

  return lastPart
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const statusColor: Record<ProcessStatus, string> = {
  [ProcessStatus.FINISHED_ERROR]: "#d52d30",
  [ProcessStatus.FINISHED_SUCCESS]: "#25C46D",
  [ProcessStatus.IN_PROGRESS]: "#3B82F6",
  [ProcessStatus.NOT_STARTED]: "#9FA1AB",
  [ProcessStatus.WAITING_ON_APPROVAL]: "#F7CB19",
};

export const TABLE_COLUMNS = [
  {
    title: "Roster",
    dataIndex: ["details", "file", "originalName"],
  },
  {
    title: "Status",
    render: (record: IngestionProcess) => {
      const color = statusColor[record.status];
      return <Tag color={color}>{getTitleFromKey(record.status)}</Tag>;
    },
  },
  {
    title: "Current step",
    render: (record: IngestionProcess) => {
      const color =
        record.status === ProcessStatus.FINISHED_ERROR ? "#d52d30" : "#3B82F6";
      return <Tag color={color}>{getTitleFromKey(record.currentStep)}</Tag>;
    },
  },
  {
    title: "Process start",
    dataIndex: "uploadedAt",
    render: (val: Date) => dayjs(val).format("M/DD/YYYY HH:mm a"),
  },
  {
    title: "Approved",
    render: (record: IngestionProcess) => {
      const status = record.approvement.approveStatus;
      if (status) {
        const icon =
          status === ApprovalStatus.APPROVED ? (
            <CheckOutlined />
          ) : (
            <CloseOutlined />
          );
        return icon;
      }
    },
  },
];

export const SUMMARY_TABLE_COLUMNS = [
  {
    title: "",
    dataIndex: "status",
    render: (val: StepStatus) => mapStatusIcon[val],
  },
  {
    title: "Step",
    dataIndex: "step",
    render: (val: ProcessStep) => mapStepName[val],
  },
  {
    title: "Notes",
    dataIndex: "notes",
  },
  {
    title: "Started At",
    dataIndex: "startedAt",
    render: (val: Date) =>
      val ? dayjs(val).format("M/DD/YYYY HH:mm:ss") : "-",
  },
  {
    title: "Duration",
    render: ({
      finishedAt,
      startedAt,
    }: {
      finishedAt: Date;
      startedAt: Date;
    }) => {
      if (!startedAt || !finishedAt) {
        return "-";
      }

      const date1 = dayjs(startedAt);
      const date2 = dayjs(finishedAt);

      const diffMilliseconds = date2.diff(date1);

      let output;
      if (diffMilliseconds < 1000) {
        if (diffMilliseconds === 0) {
          output = "N/A";
        } else {
          output = `${diffMilliseconds} ms`;
        }
      } else {
        const seconds = Math.floor(diffMilliseconds / 1000);
        const milliseconds = diffMilliseconds % 1000;
        output = `${seconds}.${milliseconds} s`;
      }

      return output;
    },
  },
];

export const ROSTERS_TABLE_COLUMNS = ({ page }: { page: number }) => [
  Table.EXPAND_COLUMN,
  {
    title: "",
    key: "index",
    render: (_: any, __: any, index: number) => (page - 1) * 10 + (index + 1),
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (val: string) => (
      <div className="flex flex-row items-center gap-x-3">
        {val === RosterStatus.INVALID ? (
          <WarningFilled className="text-red-500" />
        ) : (
          <CheckCircleFilled className="text-green-500" />
        )}
        <span className="capitalize font-semibold">{val}</span>
      </div>
    ),
  },
  {
    title: "First name",
    dataIndex: [
      "payload",
      "provider",
      "content",
      "personal_information",
      "name",
      "0",
      "first",
    ],
  },
  {
    title: "Last name",
    dataIndex: [
      "payload",
      "provider",
      "content",
      "personal_information",
      "name",
      "0",
      "last",
    ],
  },
  {
    title: "NPI",
    dataIndex: [
      "payload",
      "provider",
      "content",
      "personal_information",
      "npi",
      "0",
      "npi",
    ],
  },
  {
    title: "Locations",
    dataIndex: ["payload", "locations"],
    render: (locations: Location[]) => (
      <div className="flex gap-x-2 w-full">
        <HomeFilled className="text-green-700" />
        <span>{locations.length}</span>
      </div>
    ),
  },
  {
    title: "Errors",
    render: (val: Roster) => {
      if (val.errors.length > 0) {
        return (
          <ErrorPopoverList
            title="Errors"
            errors={val.errors}
            className="text-red-500"
          />
        );
      }
    },
  },
  {
    title: "Warnings",
    render: (val: Roster) => {
      if (val.warnings.length > 0) {
        return (
          <ErrorPopoverList
            title="Warnings"
            errors={val.warnings}
            className="text-yellow-500"
          />
        );
      }
    },
  },
];
