export const safeAccess = <T, U>(
  obj: T,
  accessor: (x: T) => U
): U | undefined => {
  try {
    return accessor(obj);
  } catch (error) {
    return undefined;
  }
};
