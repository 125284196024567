import * as XLSX from "xlsx";
import { getTitleFromKey } from "../../utils";

export const readSheetsInFile = async (
  file: File
): Promise<{
  sheets: string[];
}> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target!.result;
      const workBook = XLSX.read(bstr, {
        type: "binary",
        cellDates: true,
      });

      const sheets = workBook.SheetNames;

      resolve({
        sheets,
      });
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
};

type TreeNode = {
  value: string;
  title?: string;
  selectable?: boolean;
  children: TreeNode[];
};

export const processTreeNodes = (nodes: TreeNode[]): any => {
  return nodes.map((node) => {
    const hasChildren = Boolean(node.children);
    return {
      ...node,
      title: node.title || getTitleFromKey(node.value),
      selectable: !hasChildren,
      children: hasChildren ? processTreeNodes(node.children) : undefined,
    };
  });
};
