import { GroupType } from "../../../../core/types/provider-group";

export const mapGroupName: Record<GroupType, string> = {
  [GroupType.PAYERS]: "Payers",
  [GroupType.NETWORK]: "Network",
  [GroupType.IPA]: "Independent Physician Association (IPA)",
  [GroupType.HOSPITAL_SYSTEM]: "Hospital System",
  [GroupType.MEDICAL_GROUP]: "Medical Group",
  [GroupType.OTHER]: "Other",
};
