import { ProviderDataModel } from "./provider";
import { Location } from "./provider-location";

export type Mapping = {
  columnIndex: number;
  key: string;
  label: string;
};

export type MappingTemplate = {
  _id?: string;
  name: string;
  mappings: Mapping[];
};
export enum ProcessStatus {
  NOT_STARTED = "not_started",
  IN_PROGRESS = "in_progress",
  FINISHED_SUCCESS = "finished_success",
  FINISHED_ERROR = "finished_error",
  WAITING_ON_APPROVAL = "waiting_on_approval",
}

export enum ProcessStep {
  FILE_UPLOAD = "file_upload",
  FILE_VALIDATION = "file_validation",
  ADDRESS_NORMALIZATION = "address_normalization",
  NPI_VALIDATION = "npi_validation",
  RECORD_VALIDATION = "record_validation",
  DETECTING_CHANGES = "detecting_changes",
  WAITING_ON_APPROVAL = "waiting_on_approval",
  APPLYING_CHANGES = "applying_changes",
  FINISHED = "finished",
}

export enum ApprovalStatus {
  APPROVED = "approved",
  REJECTED = "rejected",
}

export enum StepStatus {
  SKIPPED = "skipped",
  NOT_STARTED = "not_started",
  IN_PROGRESS = "in_progress",
  FINISHED_SUCCESS = "finished_success",
  FINISHED_ERROR = "finished_error",
}

export type ProcessError = {
  step: ProcessStep;
  message: string;
};

export type ProcessStepError = Omit<ProcessError, "step">;
export type ProcessStepWarning = ProcessStepError;

export type ProcessStepNote = {
  message: string;
};

export type IngestionProcessStep = {
  status: StepStatus;
  startedAt: Date;
  finishedAt: Date;
  notes?: ProcessStepNote[];
  errors?: ProcessStepError[];
  warnings?: ProcessStepError[];
};

export type RosterType = {
  provider: {
    content: ProviderDataModel;
    adds: Change[];
    changes: Change[];
  };
  locations: {
    content: Location;
    adds: Change[];
    changes: Change[];
  }[];
};

export enum RosterStatus {
  CORRECT = "correct",
  INVALID = "invalid",
}

export type RosterError = {
  // add here other necessary fields
  step: ProcessStep;
  message: string;
};

export type Change<T = any> = {
  path: string;
  prevValue: T;
  newValue: T;
};

// export type Discrepancies = {
//   provider: ACT;
//   location: ACT[];
//   providerLocationRelation?: ACT[];
//   providerNetworkRelation?: ACT[];
// };

// TODO: to defined
export type TermType = any;

// export type ACT = {
//   adds: Change[];
//   changes: Change[];
//   terms?: TermType[];
// };

export type Roster = {
  errors: RosterError[];
  warnings: RosterError[];
  payload: RosterType;
  status: RosterStatus;
  // discrepancies: Discrepancies;
};

type ProcessApprovement = {
  approvedAt: Date;
  approvedBy: string;
  approveStatus: ApprovalStatus;
  approvedTaskToken: string;
};

type ProcessDetails = {
  file: {
    s3: {
      bucket: string;
      key: string;
    };
    originalName: string;
    rosterIndex: number;
    sheetIndex: number;
  };
  layoutId: string;
  groupId: string;
};

type ProcessSteps = Record<ProcessStep, IngestionProcessStep>;

export type IngestionProcess = {
  readonly _id: string;
  readonly status: ProcessStatus;
  readonly currentStep: ProcessStep;
  readonly details: ProcessDetails;
  readonly approvement: ProcessApprovement;
  readonly uploadedAt: Date;
  readonly uploadedBy: string;
  readonly executionArn?: string;
  readonly errors: ProcessError[];
  readonly rosters?: Roster[];
  readonly steps: ProcessSteps;
};
