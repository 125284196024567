import { WarningFilled } from "@ant-design/icons";
import { List, Popover } from "antd";
import { RosterError } from "../../../core/types/ingestion-process";

interface Props {
  title: string;
  className?: string;
  errors: RosterError[];
}
export const ErrorPopoverList = ({
  title,
  errors = [],
  className = "",
}: Props) => {
  const content = (
    <List
      dataSource={errors}
      renderItem={(error) => (
        <div className="flex w-full gap-x-2">
          <WarningFilled className={className} />
          <span>{error.message}</span>
        </div>
      )}
    />
  );

  return (
    <Popover content={content} title={title}>
      <div className="cursor-pointer flex items-center w-full h-full flex flex-row gap-x-1">
        <WarningFilled className={className} />
        <span>{errors.length ?? 0}</span>
      </div>
    </Popover>
  );
};
