import { UseQueryOptions, useQuery } from "react-query";
import api from "../libs/api";

type QueryClientParams<T> = {
  queryKey: string[];
  url: string;
  params?: any;
  options?: UseQueryOptions<T, unknown, T, string[]>;
};
export function useReactQuery<T>({
  queryKey = [],
  url = "",
  options = {},
  params = {},
}: QueryClientParams<T>) {
  const query = useQuery(queryKey, () => api.get<T>(url, params), options);
  return query;
}
