import AdminPage from "../../features/admin";
import LoginPage from "../../features/auth/Login";
import MfaSetupPage from "../../features/auth/MfaSetup";
import NewPasswordRequiredPage from "../../features/auth/NewPasswordRequired";
import TotpTokenInput from "../../features/auth/TotpTokenInput";
import IngestionsProcessTablePage from "../../features/ingestion/IngestionsProcessTablePage";
import IngestionProcessPage from "../../features/ingestion/IngestionProcess";
import ProviderPage from "../../features/provider";
import ProvidersTablePage from "../../features/providers";
import SettingsPage from "../../features/settings";
import { RouteDescriptor } from "../types/routes";
import { GroupsPage } from "../../features/group";
import { LocationsPage } from "../../features/location";

export enum ROUTES {
  LOGIN = "/auth/login",
  CHANGE_PASSWORD = "/auth/npr",
  MFA_SETUP = "/auth/mfa-setup",
  TWO_FACTOR = "/auth/two-factor",

  PROVIDERS = "/professionals",
  PROVIDER = "/professionals/:id",
  LOCATIONS = "/locations",
  GROUPS = "/groups",
  INGESTIONS = "/ingestions",
  INGESTION = "/ingestions/:id",

  SETTINGS = "/settings",

  ADMIN_PANEL = "/admin",
}

export const PUBLIC_ROUTES = [
  ROUTES.LOGIN,
  ROUTES.CHANGE_PASSWORD,
  ROUTES.MFA_SETUP,
  ROUTES.TWO_FACTOR,
];
export const PRIVATE_ROUTES = [
  ROUTES.PROVIDERS,
  ROUTES.ADMIN_PANEL,
  ROUTES.PROVIDER,
  ROUTES.SETTINGS,
  ROUTES.INGESTIONS,
  ROUTES.INGESTION,
  ROUTES.LOCATIONS,
  ROUTES.GROUPS,

  ROUTES.ADMIN_PANEL,
];
export const ADMIN_ROUTES = [ROUTES.ADMIN_PANEL];

const getPublicRoutes = (): RouteDescriptor[] => {
  return [
    {
      path: ROUTES.LOGIN,
      component: LoginPage,
    },
    {
      path: ROUTES.CHANGE_PASSWORD,
      component: NewPasswordRequiredPage,
    },
    {
      path: ROUTES.MFA_SETUP,
      component: MfaSetupPage,
    },
    {
      path: ROUTES.TWO_FACTOR,
      component: TotpTokenInput,
    },
  ];
};

export const getPrivateRoutes = (): RouteDescriptor[] => {
  return [
    {
      path: ROUTES.PROVIDERS,
      component: ProvidersTablePage,
    },
    {
      path: ROUTES.PROVIDER,
      component: ProviderPage,
    },
    {
      path: ROUTES.ADMIN_PANEL,
      component: AdminPage,
    },
    {
      path: ROUTES.SETTINGS,
      component: SettingsPage,
    },
    {
      path: ROUTES.INGESTIONS,
      component: IngestionsProcessTablePage,
    },
    {
      path: ROUTES.INGESTION,
      component: IngestionProcessPage,
    },
    {
      path: ROUTES.LOCATIONS,
      component: LocationsPage,
    },
    {
      path: ROUTES.GROUPS,
      component: GroupsPage,
    },
  ];
};

export const getAppRoutes = (): RouteDescriptor[] => [
  ...getPublicRoutes(),
  ...getPrivateRoutes(),
];
