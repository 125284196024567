import { TypeOfTax } from "../provider-location/tax-information.types";
import { ContactInformation } from "../provider/contact-information.types";
import { Metadata } from "../provider/metadata.types";
import { Name } from "../provider/personal-information.types";

export enum GroupType {
  PAYERS = "payers",
  NETWORK = "network",
  IPA = "independent_physician_association",
  HOSPITAL_SYSTEM = "hospital_system",
  MEDICAL_GROUP = "medical_group",
  OTHER = "other",
}

type GeneralInformation = {
  metadata: Metadata;
  group_type: GroupType; // Enum, payer, providers, etc.
  name: string;
  contact_information: ContactInformation;
};

type TaxInformation = {
  metadata: Metadata;
  name_on_W9: string;
  tax_id: number;
  type_of_tax_id: TypeOfTax;
  primary_location_for_tax_id: boolean;
};

type Contact = {
  metadata: Metadata;
  name: Name;
  contact_information: ContactInformation;
};

type Relation = {
  metadata: Metadata;
  uuid: string;
};

export type GroupsQueryResponse = {
  _id: string;
  name: string;
  groupType: string;
};

export type Group = {
  _id: string;

  UUID: string;
  metadata: Metadata;
  general_information: GeneralInformation[];
  tax_information: TaxInformation;
  contact: Contact[];
  groups: Relation[];
  providers: Relation[];
  locations: Relation[];
};
