import { Alert, message, Modal } from "antd";
import { FC, useState } from "react";
import { UploadDagger } from "../upload/Upload.Dagger";
import { RcFile } from "antd/es/upload";
import api from "../../../core/libs/api";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
}

export const ImportFilesModal: FC<Props> = ({ isOpen, onClose, refetch }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isConfirmMessage, setConfirmMessage] = useState<boolean>(false);

  const [files, setFiles] = useState<RcFile[]>([]);

  const onCancel = () => {
    setFiles([]);
    refetch();
    onClose();
    setConfirmMessage(false);
  };

  const onUpload = async () => {
    setLoading(true);

    const formData = new FormData();

    for (const file of files) {
      formData.append("files", file);
    }

    await api
      .post("/data-import/caqh/batch", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        setConfirmMessage(true);
        setFiles([]);
      })
      .catch((error) => {
        message.error(error?.message ?? "Unexpected error!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      width={850}
      open={isOpen}
      onCancel={onCancel}
      maskClosable={false}
      confirmLoading={loading}
      title="Import CAQH files"
      footer={null}
    >
      <Alert
        className="mt-5 mb-2"
        type="info"
        showIcon
        message="The data is assigned to a specific provider by NPI number. If there is no provider with a specific number yet - a new one will be created based on the data from the file. You can find manual creation of providers in the admin panel."
      />

      {isConfirmMessage && (
        <Alert
          className="mt-2 mb-5"
          showIcon
          type="success"
          message="The files were loaded correctly. Refresh the list of providers."
        />
      )}
      <UploadDagger
        isLoading={loading}
        onSubmit={onUpload}
        files={files}
        setFiles={setFiles}
      />
    </Modal>
  );
};
