/* eslint-disable react-hooks/exhaustive-deps */
import Icon, {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Space,
  Card,
  Flex,
  Button,
  Select,
  Popconfirm,
  Table,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { IoMdRefresh } from "react-icons/io";
import { Fragment } from "react/jsx-runtime";
import { useReactQuery } from "../../core/hooks/useReactQuery";
import api from "../../core/libs/api";
import { USER_ROLE, User } from "../../core/types/auth";
import { AddAccountModal } from "./modals/AddAccountModal";

import Search from "antd/es/input/Search";
import { mapRoleName } from "./utils";

export const UsersTable = () => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>();
  const [role, setRole] = useState<USER_ROLE>(USER_ROLE.SUPPORT);

  const {
    data = [],
    isLoading,
    isRefetching,
    refetch,
  } = useReactQuery<User[]>({
    queryKey: ["users"],
    url: "/users",
    params: { role, search },
  });

  useEffect(() => {
    refetch();
  }, [search, role]);

  const onPageRefresh = () => refetch();

  const onRemove = async (userId: string) => {
    await api
      .delete(`/users/${userId}`)
      .then(() => onPageRefresh())
      .catch((err) => {
        message.error(err.message);
      });
  };

  const columns = () => {
    const response = [
      {
        title: "Name",
        render: ({ firstName, lastName }: User) => `${firstName} ${lastName}`,
      },
      {
        render: ({ contact }: User) => contact.email,
        title: "Email",
      },

      {
        dataIndex: "status",
        title: "Status",
      },
      // {
      //   dataIndex: "roles",
      //   title: "Role",
      // },
      {
        render: ({ cognitoGuid }: User) =>
          cognitoGuid ? <CheckOutlined /> : <CloseOutlined />,
        title: "Auth",
      },
      {
        title: "Created by",
        render: ({ properties }: User) => properties.registrationType,
      },
      {
        title: "",
        render: (val: User) => (
          <Popconfirm onConfirm={() => onRemove(val._id)} title="Are you sure?">
            <DeleteOutlined />
          </Popconfirm>
        ),
      },
    ];

    if (role === USER_ROLE.PROVIDER) {
      response.splice(3, 0, {
        dataIndex: "npi",
        title: "NPI",
      });
    }

    return response;
  };
  return (
    <Fragment>
      <Card
        className="mt-5"
        title="Users"
        extra={
          <Flex gap="large" wrap="wrap">
            <Button
              type="default"
              onClick={onPageRefresh}
              icon={
                <Icon component={IoMdRefresh} style={{ fontSize: "22px" }} />
              }
            />
            <Button
              type="primary"
              onClick={() => setOpen(true)}
              icon={<PlusOutlined />}
            >
              Add new
            </Button>
          </Flex>
        }
      >
        <Space className="w-full mb-5 justify-end" direction="horizontal">
          <Search
            allowClear
            defaultValue={search}
            onSearch={(e) => setSearch(e)}
            className="min-w-[500px]"
            placeholder="Search by name or email"
          />

          <Select
            placeholder="Select role"
            defaultValue={role}
            onSelect={(e) => setRole(e)}
            className="min-w-[100px]"
          >
            {[USER_ROLE.ADMIN, USER_ROLE.SUPPORT, USER_ROLE.PROVIDER].map((role, index) => (
              <Select.Option key={index} value={role}>
                {mapRoleName[role]}
              </Select.Option>
            ))}
          </Select>
        </Space>
        <Table
          rowKey="_id"
          dataSource={data}
          loading={isLoading || isRefetching}
          pagination={{
            pageSize: 5,
          }}
          columns={columns()}
        />
      </Card>
      <AddAccountModal isOpen={isOpen} onClose={() => setOpen(false)} />
    </Fragment>
  );
};
