import {
  Layout,
  Typography,
  Dropdown,
  MenuProps,
  Space,
  Flex,
  Input,
} from "antd";
import { Content } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import { useReactQuery } from "../../../core/hooks/useReactQuery";
import { useLocation } from "react-router-dom";
import {
  isPathNameKey,
  pathNames,
  stringWithoutExtraChar,
} from "../../../core/utils/search-bar.utils";
import {
  EllipsisOutlined,
  ExportOutlined,
  ImportOutlined,
  ReloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { ImportFilesModal } from "../../../features/providers/modals/ImportFilesModal";
import { ConfirmBatchExportModal } from "../../../features/providers/modals/ConfirmBatchExportModal";
import { SearchBarList } from "./SearchBarList";

export const SearchBar = () => {
  const [search, setSearch] = useState<string>();
  const [isExportMode, setExportMode] = useState<boolean>(false);
  const [isExportModal, setExportModal] = useState<boolean>(false);
  const [isImportModal, setImportModal] = useState<boolean>(false);
  const [selectedProviders, setSelectedProviders] = useState<string[]>([]);
  const location = useLocation();

  const dropdownMenu = () => {
    const items: MenuProps["items"] = [
      {
        key: "import",
        label: "Import",
        onClick: () => setImportModal(true),
        icon: <ImportOutlined />,
      },
      {
        key: "export",
        label: "Export",
        onClick: () => setExportMode(!isExportMode),
        icon: <ExportOutlined />,
      },
    ];

    return items;
  };

  const pathnameKey = "/" + location.pathname.split("/")[1];

  const onCancelExportModal = () => {
    setExportModal(false);
    setExportMode(false);
    setSelectedProviders([]);
  };

  const onCancelImportModal = () => setImportModal(false);

  if (!isPathNameKey(pathnameKey)) {
    throw new Error("Path not found");
  }

  const currentPath = pathNames[pathnameKey];

  const {
    data = [],
    refetch,
    isLoading,
    isRefetching,
  } = useReactQuery<[]>({
    queryKey: [currentPath.queryKey],
    url: currentPath.url,
    params: { search },
  });

  useEffect(() => {
    refetch();
  }, [refetch, search]);

  return (
    <Layout className="max-w-[325px]  border-right bg-white">
      <Flex
        gap="middle"
        align="center"
        justify="space-between"
        className="px-[10px] mt-[15px]"
      >
        <Typography.Text className="text-[20px] font-semibold text-sky-500 capitalize">
          {stringWithoutExtraChar(location.pathname)}
        </Typography.Text>

        <Dropdown
          menu={{
            items: dropdownMenu(),
          }}
        >
          <Space
            onClick={(e) => e.preventDefault()}
            className="align-baseline cursor-pointer text-lightBlue text-[18px]"
          >
            <EllipsisOutlined />
          </Space>
        </Dropdown>
      </Flex>
      <Flex
        gap="middle"
        align="center"
        className="flex-col text-start py-3 px-5 border-bottom"
      >
        <Flex className="flex-row w-full gap-x-2">
          <Input
            onChange={(e) => setSearch(e.target.value)}
            className="w-full search-input-border"
            placeholder="Search for professional"
            allowClear
            rootClassName="rounded-md"
            suffix={<SearchOutlined />}
          />

          <ReloadOutlined
            onClick={() => refetch()}
            className="cursor-pointer"
          />
        </Flex>

        <span className="w-full text-xs text-start select-none">
          Total professionals: {data.length}
        </span>
      </Flex>
      <Content className="overflow-y-scroll w-full mt-[5px]">
        <SearchBarList
          data={data}
          isLoading={isLoading}
          isRefetching={isRefetching}
          isExport={isExportMode}
          setSelectedProviders={setSelectedProviders}
          setExportModal={setExportModal}
          selectedProviders={selectedProviders}
          onCancelExportModal={onCancelExportModal}
        />
      </Content>
      <ConfirmBatchExportModal
        isOpen={isExportModal}
        onClose={onCancelExportModal}
        selectedProviders={selectedProviders}
      />
      <ImportFilesModal
        isOpen={isImportModal}
        onClose={onCancelImportModal}
        refetch={refetch}
      />
    </Layout>
  );
};
