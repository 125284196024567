import { CaretRightFilled, ReloadOutlined } from "@ant-design/icons";
import { Button, Card, Space, Table, Typography } from "antd";
import { StartProcessModal } from "./start-process/StartProcessModal";
import { Fragment, useState } from "react";
import { useReactQuery } from "../../core/hooks/useReactQuery";
import { useNavigate } from "react-router-dom";
import { IngestionProcess } from "../../core/types/ingestion-process";
import { TABLE_COLUMNS } from "./utils";

const IngestionsProcessTablePage = () => {
  const navigate = useNavigate();
  const [isStartModal, setStartModal] = useState<boolean>(false);

  const {
    data: processes,
    isLoading,
    refetch,
    isRefetching,
  } = useReactQuery<IngestionProcess[]>({
    queryKey: ["ingestions"],
    url: `/ingestion-processes`,
  });

  const onSelect = (uuid: string) => navigate(`/ingestions/${uuid}`);

  return (
    <Fragment>
      <Space className="w-full" direction="vertical">
        <Typography className="font-semibold text-2xl">
          Ingestion process
        </Typography>

        <Card className="mt-5">
          <Space direction="horizontal" className="w-full justify-end pb-5">
            <Button
              onClick={() => setStartModal(true)}
              type="primary"
              icon={<CaretRightFilled />}
            >
              New
            </Button>
            <Button
              onClick={() => refetch()}
              type="primary"
              icon={<ReloadOutlined />}
            />
          </Space>
          <Table
            rowClassName="cursor-pointer"
            dataSource={processes}
            loading={isLoading || isRefetching}
            onRow={(record) => {
              return {
                onClick: () => onSelect(record._id),
              };
            }}
            columns={TABLE_COLUMNS}
          />
        </Card>
      </Space>
      <StartProcessModal
        isOpen={isStartModal}
        onClose={() => setStartModal(false)}
      />
    </Fragment>
  );
};

export default IngestionsProcessTablePage;
