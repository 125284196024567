import { CodeOutlined, ExportOutlined } from "@ant-design/icons";
import { Row, Button } from "antd";
import { Fragment } from "react/jsx-runtime";
import { ProviderDataModel } from "../../../core/types/provider";

interface Props {
  isExport: boolean;
  data: ProviderDataModel;
  handleExportMode: (val: boolean) => void;
  onRawDataPreview: (val: boolean) => void;
}
export const ProviderDataToolbar = ({
  isExport = false,
  data,
  handleExportMode,
  onRawDataPreview,
}: Props) => {
  return (
    <Row className="gap-x-5">
      {data && (
        <Button
          icon={<ExportOutlined />}
          onClick={() => handleExportMode(!isExport)}
        >
          Export
        </Button>
      )}

      <Fragment>
        <Button icon={<CodeOutlined />} onClick={() => onRawDataPreview(true)}>
          Raw data
        </Button>
      </Fragment>
    </Row>
  );
};
