import { Col, Form, Input } from "antd";
import { checkIfValueExist } from "../../../core/utils/check-type.utils";
import { ProviderDataFormType } from "../types/Provider";

export const ProviderDataForm = ({
  path,
  label,
  value,
  onSelectField,
}: ProviderDataFormType) => {
  return (
    <Col key={path} span={6}>
      <Form.Item name={path} label={label} initialValue={value}>
        <Input
          onSelect={() => onSelectField(path)}
          value={checkIfValueExist(value) ? value.toString() : "N/A"}
          style={{
            border: "none",
            fontWeight: "500",
            backgroundColor: "inherit",
            color: "inherit",
            padding: "0",
            cursor: "auto",
          }}
        />
      </Form.Item>
    </Col>
  );
};
