import { MenuProps } from "antd";
import { SIDEBAR_ITEMS_DATA } from "../configs/sidebar-config";
import { createElement } from "react";

export const sidebarItems: MenuProps["items"] = SIDEBAR_ITEMS_DATA.map(
  (data) => {
    return {
      key: `${data.label}`,
      icon: createElement(data.icon),
      style: {
        borderBottom: "1px solid #E2E6EB",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        fontWeight: 400,
      },
      label: `${data.label}`,

      children: data.children
        ? data.children.map((child) => ({
          key: `${child.routeKey}`,
          label: child.label,
          path: child.path,
        }))
        : null,
    };
  }
);
