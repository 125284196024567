import { EducationInfo } from "../../../../core/types/provider";
import { FieldResponse, Props } from "../provider-fields";

export const education_school = ({
  payload,
  index,
  school,
}: Props<EducationInfo> & { school: string }): FieldResponse[] => [
  {
    label: "Name",
    value: payload.name,
  },
  {
    label: "Phone",
    value: payload.contact_information?.phone[0]?.phone[0]?.number,
  },
  {
    label: "Fax",
    value: payload.contact_information?.fax[0]?.fax[0],
  },
  {
    label: "Email",
    value: payload.contact_information?.email[0]?.email[0]?.email,
  },
  {
    label: "Degree",
    value: payload.degree,
  },
  {
    label: "Start date",
    value: payload.start_date,
  },
  {
    label: "End date",
    value: payload.end_date,
  },
  {
    label: "Area of training",
    value: payload.area_of_training,
  },
  {
    label: "Complete",
    value: payload.complete,
  },
  {
    label: "Graduation date",
    value: payload.graduation_date,
  },
];

export const education_school_address = ({
  payload,
  index,
  school,
}: Props<EducationInfo> & { school: string }): FieldResponse[] => [
  {
    label: "Street",
    value: payload.contact_information?.physical_address[0]?.street,
  },
  {
    label: "City",
    value: payload.contact_information?.physical_address[0]?.city,
  },
  {
    label: "State",
    value: payload.contact_information?.physical_address[0]?.state,
  },
  {
    label: "ZIP",
    value: payload.contact_information?.physical_address[0]?.zip,
  },
  {
    label: "County",
    value: payload.contact_information?.physical_address[0]?.county,
  },
  {
    label: "Country",
    value: payload.contact_information?.physical_address[0]?.country,
  },
];
