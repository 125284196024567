import {
  Alert,
  Badge,
  Card,
  Collapse,
  Form,
  Input,
  Modal,
  Space,
  message,
} from "antd";
import { FC, useEffect, useState } from "react";
import api from "../../../core/libs/api";
import { ColumnsSection } from "../export-templates/ColumnSection";
import {
  educationColumns,
  personalInformationColumns,
  pinColumns,
  trainingColumns,
} from "../export-templates/utils/export-columns";
import { ExportTemplateType } from "../../../core/types/export-template";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  template?: ExportTemplateType;
}

interface FormProps {
  name: string;
  description: string;
}

export const AddExportTemplateModal: FC<Props> = ({
  isOpen,
  onClose,
  template,
}) => {
  const [form] = Form.useForm();

  const [selectedFields, setSelectedFields] = useState<string[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (template) {
      form.setFieldValue("name", template.name);
      form.setFieldValue("description", template.description);

      setSelectedFields(template.fields);
    }
  }, [form, template]);

  const onFinish = async (form: FormProps) => {
    setLoading(true);

    if (selectedFields.length === 0) {
      message.error("You have to select at least one field.");
    }

    const payload = {
      ...form,
      fields: selectedFields,
      templateId: !template ? undefined : template._id,
    };
    await api
      .post("/data-export/template", payload)
      .then(() => {
        message.success("Template successfully created.");
        onCancel();
      })
      .catch(() => {
        message.error("Something went wrong. Try again later.");
      })
      .finally(() => setLoading(false));
  };

  const submitForm = () => form.submit();

  const onCancel = () => {
    form.resetFields();
    setSelectedFields([]);
    setErrors([]);
    onClose();
  };

  const onSelect = (key: string) => {
    const isExists = selectedFields.find((e) => e === key);
    if (!isExists) {
      setSelectedFields([...selectedFields, key]);
    } else {
      const fields = selectedFields.filter((e) => e !== key);
      setSelectedFields(fields);
    }
  };

  const fieldsCount = (key: string) => {
    return selectedFields.filter((e) => e.includes(key)).length;
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onCancel}
      onOk={submitForm}
      confirmLoading={loading}
      title={`${template ? "Update" : "Crete new"} Export Template`}
      width={1000}
      okText={template ? "Update" : "Submit"}
    >
      <Form
        className="pt-12"
        layout="vertical"
        form={form}
        name="createNewExportTemplate"
        onFinish={onFinish}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: false }]}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>

      <Alert
        className="my-5"
        showIcon
        type="info"
        message="Select the fields to be exported to the spreadsheet."
      />

      <Card title="Columns">
        <Collapse
          items={[
            {
              label: "Personal informations",
              extra: (
                <Badge
                  color="blue"
                  count={fieldsCount("personal_information")}
                />
              ),
              children: (
                <Space direction="vertical">
                  <ColumnsSection
                    selectedFields={selectedFields}
                    onSelect={onSelect}
                    columns={personalInformationColumns.name}
                    title="Name"
                  />
                  <ColumnsSection
                    selectedFields={selectedFields}
                    onSelect={onSelect}
                    columns={personalInformationColumns.homeAddress}
                    title="Home address"
                  />
                  <ColumnsSection
                    selectedFields={selectedFields}
                    onSelect={onSelect}
                    columns={personalInformationColumns.mailingAddress}
                    title="Mailing address"
                  />
                  <ColumnsSection
                    selectedFields={selectedFields}
                    onSelect={onSelect}
                    columns={personalInformationColumns.demographics}
                    title="Demographics"
                  />
                  <ColumnsSection
                    selectedFields={selectedFields}
                    onSelect={onSelect}
                    columns={personalInformationColumns.other}
                    title="Other"
                  />
                </Space>
              ),
            },
            {
              label: "Professional identification numbers",
              extra: (
                <Badge
                  color="blue"
                  count={fieldsCount("professional_identification_numbers")}
                />
              ),
              children: (
                <Space direction="vertical">
                  <ColumnsSection
                    selectedFields={selectedFields}
                    onSelect={onSelect}
                    columns={pinColumns.license}
                    title="License"
                  />
                  <ColumnsSection
                    selectedFields={selectedFields}
                    onSelect={onSelect}
                    columns={pinColumns.medicare}
                    title="Medicare"
                  />
                  <ColumnsSection
                    selectedFields={selectedFields}
                    onSelect={onSelect}
                    columns={pinColumns.other}
                    title="Other"
                  />
                </Space>
              ),
            },
            {
              label: "Education",
              extra: <Badge color="blue" count={fieldsCount("education")} />,
              children: (
                <Space direction="vertical">
                  <ColumnsSection
                    selectedFields={selectedFields}
                    onSelect={onSelect}
                    columns={educationColumns.professional}
                    title="Professional School"
                  />
                  <ColumnsSection
                    selectedFields={selectedFields}
                    onSelect={onSelect}
                    columns={educationColumns.graduate}
                    title="Graduate"
                  />
                  <ColumnsSection
                    selectedFields={selectedFields}
                    onSelect={onSelect}
                    columns={educationColumns.undergraduate}
                    title="Undergraduate"
                  />
                </Space>
              ),
            },
            {
              label: "Training",
              extra: <Badge color="blue" count={fieldsCount("training")} />,
              children: (
                <Space direction="vertical">
                  <ColumnsSection
                    selectedFields={selectedFields}
                    onSelect={onSelect}
                    columns={trainingColumns.internship}
                    title="Internship"
                  />
                  <ColumnsSection
                    selectedFields={selectedFields}
                    onSelect={onSelect}
                    columns={trainingColumns.other}
                    title="Other"
                  />
                </Space>
              ),
            },
          ]}
        />
      </Card>

      <Space direction="vertical" className="w-full">
        {errors &&
          Array.isArray(errors) &&
          errors?.map((err, index) => (
            <Alert key={index} message={err} type="error" showIcon />
          ))}
      </Space>
    </Modal>
  );
};
