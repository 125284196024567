import { Card, Collapse, Form, Typography } from "antd";
import { Fragment } from "react/jsx-runtime";
import { ContentPreviewModal } from "../providers/uploaded-files/ContentPreviewModal";
import { useState } from "react";
import { ProviderDataToolbar } from "./components/ProviderDataToolbar";
import { NoProviderDataBanner } from "./components/NoProviderDataBanner";
import { ExportModal } from "../../components/ExportModal";
import { PageLoading } from "../../components/PageLoading";
import { Props } from "./types/Provider";
import { getSections } from "./utils/sections";
import { DataCard } from "./components/DataCard";
import { ProviderMainInfo } from "./components/ProviderMainInfo";
import { ConfirmBatchExportModal } from "../providers/modals/ConfirmBatchExportModal";
import { ProviderPracticeLocations } from "./components/ProviderPracticeLocations";

export const ProviderDataPreview = ({ provider, refetch }: Props) => {
  const [isOpenRawPreview, setOpenRawPreview] = useState<boolean>(false);
  const [isOpenExportModal, setOpenExportModal] = useState<boolean>(false);
  const [isExport, setExport] = useState<boolean>(false);
  const [selectedFieldsToExport, setSelectedFieldsToExport] = useState<
    string[]
  >([]);

  const currentProvider = provider!.UUID;

  const [form] = Form.useForm();

  if (!provider) {
    return <PageLoading />;
  }

  const onClosePreview = () => {
    setOpenRawPreview(false);
    refetch();
  };

  const onSelectField = (val: string) => {
    if (selectedFieldsToExport.includes(val)) {
      const res = selectedFieldsToExport.filter((e) => e !== val);
      setSelectedFieldsToExport(res);
    } else {
      setSelectedFieldsToExport([...selectedFieldsToExport, val]);
    }
  };

  const onExportCancel = (val: boolean) => {
    setExport(val);

    if (!val) {
      setSelectedFieldsToExport([]);
    }
  };

  if (!provider) {
    return <NoProviderDataBanner refetch={refetch} />;
  }

  const handleFinish = (values: any) => {
    console.log("Form values:", values);
  };

  const onCancelExportModal = () => {
    setExport(false);
    setOpenExportModal(false);
    setSelectedFieldsToExport([]);
  };

  const sections = getSections(provider);

  return (
    <Fragment>
      <div className="flex items-center justify-between">
        <Typography.Text className="text-[18px] font-semibold text-sky-500 ml-3">
          Professional Details
        </Typography.Text>
        <ProviderDataToolbar
          data={provider}
          isExport={isExport}
          handleExportMode={onExportCancel}
          onRawDataPreview={setOpenRawPreview}
        />
      </div>

      <ProviderMainInfo provider={provider} />

      <ProviderPracticeLocations provider={provider} />

      <Card
        title="User Defined Fields"
        className="mt-5 shadow"
        styles={{ header: { color: "#10A5EA" } }}
      >
        <Form form={form} onFinish={handleFinish} layout="vertical" disabled>
          <Collapse defaultActiveKey={["Personal Information"]}>
            {Object.entries(sections).map(([sectionTitle, sectionItems]) => (
              <Collapse.Panel header={sectionTitle} key={sectionTitle}>
                {sectionItems.map(({ title, data, dataMapper }) => (
                  <DataCard
                    key={title}
                    title={title}
                    data={data}
                    dataMapper={dataMapper}
                    onSelectField={onSelectField}
                  />
                ))}
              </Collapse.Panel>
            ))}
          </Collapse>
        </Form>
      </Card>
      <ContentPreviewModal
        isOpen={isOpenRawPreview}
        onClose={() => onClosePreview()}
        content={provider}
      />

      <ExportModal
        content={
          <Typography>Are you sure that you want to export data?</Typography>
        }
        isOpen={isOpenExportModal}
        onClose={() => {
          setExport(false);
          setOpenExportModal(false);
          setSelectedFieldsToExport([]);
        }}
        apiProps={{
          fields: selectedFieldsToExport,
          providers: [provider.UUID],
        }}
      />

      <ConfirmBatchExportModal
        isOpen={isExport}
        onClose={onCancelExportModal}
        selectedProviders={[currentProvider]}
      />
    </Fragment>
  );
};
