import { IngestionProcess } from "../../../core/types/ingestion-process";
import { joinClasses } from "../../../core/utils/classes.utils";
import { DiscrepancyType } from "./ApprovementSection";
import { calculateTotalDiscrepancies } from "../start-process/components/utils";

interface Props {
  process: IngestionProcess;
  openPreviewModal: (type: DiscrepancyType) => void;
}

interface TableContentProps {
  children: string | number;
  className?: string;
  onClick?: () => void;
}

export const TableOfChanges = ({ process, openPreviewModal }: Props) => {
  const discrepancies = calculateTotalDiscrepancies(process?.rosters ?? []);

  const Th = ({ children, className = "" }: TableContentProps) => (
    <th
      className={joinClasses(
        "border-b border-gray-400 p-2 text-start",
        className
      )}
    >
      {children}
    </th>
  );

  const Td = ({
    children,
    className = "",
    onClick = undefined,
  }: TableContentProps) => (
    <td
      onClick={onClick && onClick}
      className={joinClasses(
        "border-b border-gray-200 p-2 text-start",
        className
      )}
    >
      {children}
    </td>
  );

  return (
    <div className="flex justify-center mt-5">
      <table className="table-fixed border-collapse border border-gray-300 mt-4 w-full">
        <thead>
          <tr className="bg-gray-100">
            <Th>Provider</Th>
            <Th>Count</Th>
          </tr>
        </thead>
        <tbody>
          <tr className="border-t border-gray-400">
            <Td>Add</Td>
            <Td
              onClick={() => openPreviewModal(DiscrepancyType.PROVIDER)}
              className="underline text-blue-500 cursor-pointer"
            >
              {discrepancies.providersAdds}
            </Td>
          </tr>
          <tr className="border-b border-gray-400">
            <Td>Change</Td>
            <Td
              onClick={() => openPreviewModal(DiscrepancyType.PROVIDER)}
              className="underline text-blue-500 cursor-pointer"
            >
              {discrepancies.providersChanges}
            </Td>
          </tr>
          <tr className="bg-gray-100 border-t border-gray-400">
            <Th>Service Location</Th>
            <Th>Count</Th>
          </tr>
          <tr className="border-t border-gray-400">
            <Td>Add</Td>
            <Td
              onClick={() => openPreviewModal(DiscrepancyType.LOCATION)}
              className="underline text-blue-500 cursor-pointer"
            >
              {discrepancies.locationsAdds}
            </Td>
          </tr>
          <tr>
            <Td>Change</Td>
            <Td
              onClick={() => openPreviewModal(DiscrepancyType.LOCATION)}
              className="underline text-blue-500 cursor-pointer"
            >
              {discrepancies.locationsChanges}
            </Td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
