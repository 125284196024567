import { Malpractice } from "../../../../core/types/provider";
import { FieldResponse, Props } from "../provider-fields";

export const malpractice = ({
  payload,
}: Props<Malpractice>): FieldResponse[] => [
  {
    label: "Extended Reporting Period Purchased",
    value: payload.extended_reporting_period_purchased,
  },
  {
    label: "Practiced Without Professional Liability Coverage",
    value: payload.practiced_without_professional_liability_coverage,
  },
  {
    label: "Claims Against Provider",
    value: payload.claims_against_provider,
  },
  {
    label: "Number of Claims",
    value: payload.number_of_claims,
  },
  {
    label: "Coverage Terminated",
    value: payload.coverage_terminated,
  },
  {
    label: "Intent of Termination",
    value: payload.intent_of_termination,
  },
  {
    label: "Named in Malpractice Claim",
    value: payload.named_in_malpractice_claim,
  },
  {
    label: "Adverse Actions",
    value: payload.adverse_actions,
  },
  {
    label: "Claim Descriptions",
    value: payload.claim_descriptions,
  },
];
