import { Modal, Space, Button, Alert, message, Typography } from "antd";
import React, { Fragment, useState } from "react";
import api from "../core/libs/api";
import { ExportResponse } from "../core/types/export";
import { download } from "../core/utils/download";
import {
  CheckCircleFilled,
  DownloadOutlined,
  EyeOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

interface Props {
  content: React.ReactNode;
  apiProps: Record<string, any>;
  isOpen: boolean;
  onClose: () => void;
}
export const ExportModal = ({
  isOpen = false,
  content = undefined,
  apiProps,
  onClose,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);

  const [sheetUrl, setSheetUrl] = useState<string>();
  const [sheetId, setSheetId] = useState<string>();

  const onCancel = () => {
    setSheetUrl(undefined);
    setErrors([]);
    onClose();
  };

  const onFinish = async () => {
    setLoading(true);
    await api
      .post<ExportResponse>("/data-export/export", apiProps)
      .then(({ spreadsheetUrl, spreadsheetId }) => {
        setSheetUrl(spreadsheetUrl);
        setSheetId(spreadsheetId);
      })
      .catch((err) => {
        setErrors(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onDownload = async () => {
    await api
      .get<Blob>(
        `/data-export/download/${sheetId}`,
        {},
        {
          responseType: "blob",
        }
      )
      .then((resp: Blob) => {
        download.handleBlobFile({
          blob: resp,
          filename: `${sheetId}.xlsx`,
        });
        message.success("Export sheet downloaded.");
      })
      .catch((err) => {
        console.error(err);
        message.error("Something went wrong. Try again later.");
      });
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onCancel}
      closable={!!sheetUrl}
      maskClosable={false}
      confirmLoading={loading}
      title="Confirm export"
      footer={
        !sheetUrl &&
        !loading && (
          <Space className="pt-12">
            <Button onClick={onCancel}>Cancel</Button>
            <Button type="primary" onClick={onFinish}>
              Confirm
            </Button>
          </Space>
        )
      }
    >
      {!sheetUrl && !loading && content}

      {!sheetUrl && loading && (
        <Fragment>
          <Space direction="vertical" className="mt-5 w-full items-center">
            <LoadingOutlined className="text-3xl" />
            <Typography className="mt-5">
              Exporting data. This may take a few seconds...
            </Typography>
          </Space>
        </Fragment>
      )}

      {sheetUrl && (
        <Space direction="vertical" className="mt-5 w-full items-center">
          <CheckCircleFilled className="text-green-600 text-5xl mb-5" />
          <Typography>Your spreadsheet has been generated.</Typography>
          <Space direction="horizontal" className="mt-3">
            <Button href={sheetUrl} target="_blank" icon={<EyeOutlined />}>
              Open
            </Button>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              onClick={onDownload}
            >
              Download
            </Button>
          </Space>
        </Space>
      )}

      <Space direction="vertical" className="w-full">
        {errors &&
          Array.isArray(errors) &&
          errors?.map((err, index) => (
            <Alert key={index} message={err} type="error" showIcon />
          ))}
      </Space>
    </Modal>
  );
};
