import { useNavigate } from "react-router-dom";
import {
  ApprovalStatus,
  IngestionProcess,
  RosterStatus,
} from "../../../core/types/ingestion-process";
import {
  LeftOutlined,
  ReloadOutlined,
  LoadingOutlined,
  TeamOutlined,
  WarningOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import { Card, Tooltip, Row, Col } from "antd";
import dayjs from "dayjs";
import { DetailPill } from "./DetailPill";
import { SummaryField } from "./SummaryField";
import { toTitleCase } from "../../../core/utils/string-utils";

interface Props {
  process: IngestionProcess;
  reloadProcess: () => void;
  isReloading: boolean;
}
export const ProcessDetails = ({
  process,
  isReloading,
  reloadProcess,
}: Props) => {
  const navigate = useNavigate();

  const errorsCount = process.rosters?.filter(
    (roster) => roster.status === RosterStatus.INVALID
  ).length;

  return (
    <Card
      title={
        <div className="flex flex-row gap-x-2 items-center">
          <LeftOutlined
            onClick={() => navigate(-1)}
            className="text-xs p-1 cursor-pointer"
          />
          <span>Details</span>
        </div>
      }
      extra={
        <Tooltip title="Refresh process">
          {!isReloading ? (
            <ReloadOutlined
              className="cursor-pointer"
              onClick={() => reloadProcess()}
            />
          ) : (
            <LoadingOutlined />
          )}
        </Tooltip>
      }
      className="w-full"
    >
      <Row className="w-full flex flex-1 pt-4" gutter={24}>
        <Col span={12}>
          <SummaryField
            label="File Name"
            value={process.details.file.originalName}
          />
          {/* <SummaryField
            label="Data Layout"
            value={process.details.layoutId ?? "N/A"}
          /> */}
          <SummaryField
            label="Uploaded On"
            value={dayjs(process.uploadedAt).format("M/DD/YYYY HH:mm:ss")}
          />
          {/* <SummaryField
            label="Uploaded By"
            value={process.uploadedBy ?? "N/A"}
          /> */}
        </Col>
        <Col span={12}>
          <SummaryField
            label="Approval Status"
            value={toTitleCase(process.approvement?.approveStatus ?? "N/A")}
          />
          <SummaryField
            label={
              !process.approvement?.approveStatus ||
              process.approvement?.approveStatus === ApprovalStatus.APPROVED
                ? "Approved at"
                : "Rejected at"
            }
            value={
              process.approvement?.approvedAt
                ? dayjs(process.approvement?.approvedAt).format(
                    "M/DD/YYYY HH:mm:ss"
                  )
                : "N/A"
            }
          />
        </Col>
      </Row>
      <div className="flex flex-row w-full gap-x-5 mt-12">
        <DetailPill
          icon={<TeamOutlined className="text-lime-500 text-xl" />}
          title="Records Submitted"
          value={process.rosters?.length ?? 0}
        />
        <DetailPill
          icon={<WarningOutlined className="text-orange-500 text-xl" />}
          title="Total Errors/Warning"
          value={errorsCount ?? 0}
        />
        <DetailPill
          icon={<LineChartOutlined className="text-purple-500 text-xl" />}
          title="% Change Errors/Warnings (Last 3 Submissions)"
          value={0}
        />
      </div>
    </Card>
  );
};
