import { LoadingOutlined } from "@ant-design/icons";
import { Space, Typography, Button } from "antd";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export const PageCenter = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
`;

export const PageLoading = () => {
  const navigate = useNavigate();

  return (
    <PageCenter>
      <Space direction="vertical" className="text-center">
        <Typography>Loading...</Typography>
        <LoadingOutlined />

        <Button type="primary" onClick={() => navigate(-1)} className="mt-12">
          Cancel and back
        </Button>
      </Space>
    </PageCenter>
  );
};
