import { Space, Card, Empty } from "antd";

const ProvidersTablePage = () => {
  return (
    <Space className="w-full" direction="vertical">
      <Card className="mt-5">
        <Empty description={<span>You haven't chosen a Provider</span>} />
      </Card>
    </Space>
  );
};

export default ProvidersTablePage;
