import { Alert, Badge, Collapse, Modal, Radio, Table } from "antd";
import {
  Change,
  IngestionProcess,
  RosterStatus,
} from "../../../core/types/ingestion-process";
import { useState } from "react";
import { RostersTable } from "./RostersTable";
import { ROSTER_CHANGES_COLUMNS, ROSTERS_CHANGES_TABLE_COLUMNS } from "./utils";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import { CaretRightOutlined } from "@ant-design/icons";

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  process: IngestionProcess;
}

enum ChangesFilter {
  ALL,
  ADDS,
  CHANGES,
}

export const ChangesPreviewModal = ({ isOpen, onCancel, process }: Props) => {
  const [filter, setFilter] = useState<ChangesFilter>(ChangesFilter.ALL);

  const getRosters = () => {
    return (
      process?.rosters?.filter(
        (roster) => roster.status === RosterStatus.CORRECT
      ) ?? []
    );
  };

  const getChanges = (changes: Change[] = []) => {
    return changes.filter((change) => {
      if (filter === ChangesFilter.ADDS) {
        return !change.prevValue && change.newValue;
      } else if (filter === ChangesFilter.CHANGES) {
        return (change.prevValue && change.newValue) || !change.newValue;
      } else {
        return change;
      }
    });
  };

  return (
    <Modal
      width={1250}
      open={isOpen}
      onCancel={onCancel}
      title={`Adds, Changes & Terms`}
      footer={null}
    >
      <Alert
        showIcon
        className="my-5"
        type="info"
        message="Expand the given row to see the changes."
      />

      <div className="w-full justify-right pb-5">
        <Radio.Group defaultValue={filter} buttonStyle="solid">
          <Radio.Button
            value={ChangesFilter.ALL}
            onChange={() => setFilter(ChangesFilter.ALL)}
          >
            Everything
          </Radio.Button>
          <Radio.Button
            value={ChangesFilter.ADDS}
            onChange={() => setFilter(ChangesFilter.ADDS)}
          >
            Adds
          </Radio.Button>
          <Radio.Button
            value={ChangesFilter.CHANGES}
            onChange={() => setFilter(ChangesFilter.CHANGES)}
          >
            Changes
          </Radio.Button>
        </Radio.Group>
      </div>

      <div className="w-full">
        <RostersTable
          columns={(page) => ROSTERS_CHANGES_TABLE_COLUMNS({ page })}
          rosters={getRosters()}
          expand={(roster) => (
            <Table
              bordered
              size="small"
              dataSource={getChanges([
                ...roster.payload.provider.adds,
                ...roster.payload.provider.changes,
              ])}
              pagination={false}
              columns={ROSTER_CHANGES_COLUMNS}
              locale={{
                emptyText: "No changes for this provider.",
              }}
              footer={() => (
                <Collapse
                  bordered={false}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                >
                  {roster.payload.locations.map((location, key) => (
                    <CollapsePanel
                      header={`Location #${key + 1}`}
                      extra={
                        <Badge
                          count={
                            getChanges([...location.adds, ...location.changes])
                              .length
                          }
                        />
                      }
                      key={key}
                    >
                      <Table
                        className="pt-5"
                        bordered
                        size="small"
                        pagination={false}
                        dataSource={getChanges([
                          ...location.adds,
                          ...location.changes,
                        ])}
                        locale={{
                          emptyText: "No changes for this location.",
                        }}
                        columns={ROSTER_CHANGES_COLUMNS}
                      />
                    </CollapsePanel>
                  ))}
                </Collapse>
              )}
            />
          )}
        />
      </div>
    </Modal>
  );
};
