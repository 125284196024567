import {
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Dropdown, MenuProps, Row, Space, Typography } from "antd";
import { Header as AntdHeader } from "antd/es/layout/layout";
import { auth } from "../../core/libs/api/auth";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../core/routes";

export const Header = ({ user }: { user: any }) => {
  const navigate = useNavigate();

  const onLogout = async () => await auth.logout();

  // const isAdmin = user?.roles?.includes("admin");

  const dropdownMenu = () => {
    const items: MenuProps["items"] = [
      {
        key: "settings",
        label: "Settings",
        onClick: () => navigate(ROUTES.SETTINGS),
        icon: <SettingOutlined />,
      },
      {
        key: "logout",
        danger: true,
        label: "Logout",
        onClick: () => onLogout(),
        icon: <LogoutOutlined />,
      },
    ];

    return items;
  };

  return (
    <div className="flex flex-col w-full">
      <AntdHeader
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#1a1c20",
        }}
      >
        <Link to={ROUTES.PROVIDERS} className="w-full">
          <Typography className="font-semibold text-white">OneCred</Typography>
        </Link>
        <Row className="w-full justify-end text-white gap-x-3">
          <Dropdown
            menu={{
              items: dropdownMenu(),
            }}
          >
            <Space
              onClick={(e) => e.preventDefault()}
              className="gap-x-2 align-baseline cursor-pointer"
            >
              <UserOutlined />
              <Typography.Text className="text-white">
                {user?.firstName}
              </Typography.Text>
            </Space>
          </Dropdown>
        </Row>
      </AntdHeader>
    </div>
  );
};
