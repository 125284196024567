import {
  Medicaid,
  Medicare,
  ProfessionalIdentificationNumber,
  ProfessionalLicense,
} from "../../../../core/types/provider";
import { FieldResponse, Props } from "../provider-fields";

export const pin_licenses = ({
  payload,
  index,
}: Props<ProfessionalLicense>): FieldResponse[] => [
  {
    label: "State",
    value: payload.license_state,
  },
  {
    label: "Number",
    value: payload.license_number,
  },
  {
    label: "Status",
    value: payload.license_status,
  },
  {
    label: "Type",
    value: payload.license_type,
  },
  {
    label: "Expiration date",
    value: payload.expiration_date,
  },
  {
    label: "Issue date",
    value: payload.issue_date,
  },
  {
    label: "Practice type",
    value: payload.practice_type,
  },
  {
    label: "License entity",
    value: payload.license_entity,
  },
  {
    label: "Currently practicing",
    value: payload.currently_practicing,
  },
];

export const pin_medicare = ({
  payload,
  index,
}: Props<Medicare>): FieldResponse[] => [
  {
    label: "Participating",
    value: payload.participating,
  },
  {
    label: "Number",
    value: payload.medicare_provider_number,
  },
  {
    label: "State",
    value: payload.state,
  },
];

export const pin_medicaid = ({
  payload,
  index,
}: Props<Medicaid>): FieldResponse[] => [
  {
    label: "Participating",
    value: payload.participating,
  },
  {
    label: "Number",
    value: payload.medicaid_id_number,
  },
  {
    label: "State",
    value: payload.state,
  },
];

export const pin_others = ({
  payload,
}: Props<ProfessionalIdentificationNumber>): FieldResponse[] => {
  const response = [];
  response.push({
    label: "DEA Registration",
    value: payload.dea_registration[0]?.held,
  });

  response.push({
    label: "Dangerous substance Registration",
    value: payload.controlled_dangerous_substance_registration[0]?.held,
  });

  response.push({
    label: "Hold ECFMG number",
    value: payload.ecfmg[0]?.hold_number,
  });
  if (payload.caqh !== null) {
    response.push({
      label: "CAQH ID",
      value: payload.caqh[0]?.id,
    });
  }

  return response;
};
