import { Button, Card, Table, message } from "antd";
import { AddExportTemplateModal } from "./modals/AddExportTemplateModal";
import { Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { DeleteOutlined, EyeOutlined, PlusOutlined } from "@ant-design/icons";
import { useReactQuery } from "../../core/hooks/useReactQuery";
import Typography from "antd/es/typography/Typography";
import api from "../../core/libs/api";
import { ExportTemplateType } from "../../core/types/export-template";

export const ExportTemplateTable = () => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [template, setTemplate] = useState<ExportTemplateType>();

  const {
    data: templates = [],
    isLoading,
    refetch,
  } = useReactQuery<ExportTemplateType[]>({
    queryKey: ["export-templates"],
    url: "/data-export/templates",
  });

  const onEdit = (template: ExportTemplateType) => {
    setTemplate(template);
    setOpen(true);
  };

  const onRemove = async (id: string) => {
    await api
      .delete(`/data-export/template/${id}`)
      .then(() => {
        refetch();
        message.success("Template removed.");
      })
      .catch(() => {
        message.error("Something went wrong. Try again later.");
      });
  };

  return (
    <Fragment>
      <Card
        title="Export Templates"
        extra={
          <Button
            type="primary"
            onClick={() => setOpen(true)}
            icon={<PlusOutlined />}
          >
            Add new
          </Button>
        }
      >
        <Table
          loading={isLoading}
          dataSource={templates}
          columns={[
            {
              title: "Name",
              dataIndex: "name",
            },
            {
              title: "Description",
              dataIndex: "description",
            },
            {
              title: "Fields",
              render: (template: ExportTemplateType) => (
                <Typography>{template.fields.length}</Typography>
              ),
            },
            {
              render: (template: ExportTemplateType) => (
                <EyeOutlined onClick={() => onEdit(template)} />
              ),
            },
            {
              render: (template: ExportTemplateType) => (
                <DeleteOutlined onClick={() => onRemove(template._id)} />
              ),
            },
          ]}
        />
      </Card>
      <AddExportTemplateModal
        template={template}
        isOpen={isOpen}
        onClose={() => {
          refetch();
          setTemplate(undefined);
          setOpen(false);
        }}
      />
    </Fragment>
  );
};
