import { InboxOutlined } from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import { Button, UploadFile } from "antd";
import Upload, { RcFile } from "antd/es/upload";
import { UploadRequestOption } from "rc-upload/lib/interface";
import { joinClasses } from "../../../core/utils/classes.utils";

interface Props {
  files: RcFile[];
  setFiles: (files: RcFile[]) => void;
  onSubmit: () => void;
  isLoading: boolean;
  className?: string;
}
export const UploadDagger = ({
  files,
  setFiles,
  onSubmit,
  isLoading = false,
  className = "",
}: Props) => {
  const handleFileUpload = async ({ file, onSuccess }: UploadRequestOption) => {
    setFiles([...files, file as RcFile]);
    setTimeout(() => {
      onSuccess && onSuccess("ok");
    }, 0);
  };

  const beforeUpload = (file: RcFile) => {
    const isDuplicate = files.some(
      (f) => f.name === file.name && f.size === file.size
    );
    if (isDuplicate) {
      return Upload.LIST_IGNORE;
    }
  };

  return (
    <div className={joinClasses("w-full flex flex-col", className)}>
      <Dragger
        // accept="application/pdf"
        name="caqh-files-list"
        listType="picture"
        multiple={true}
        customRequest={handleFileUpload}
        beforeUpload={beforeUpload}
        fileList={files as UploadFile[]}
        height={200}
        onRemove={(file) => {
          setFiles(files.filter((f) => f.uid !== file.uid));
        }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">Support for a single or bulk upload.</p>
        {files?.length > 0 && (
          <span>{files?.length ?? 0} files imported</span>
        )}
      </Dragger>
      {files.length > 0 && (
        <Button
          loading={isLoading}
          onClick={() => onSubmit()}
          className="mt-5"
          type="primary"
        >
          Confirm & Upload
        </Button>
      )}
    </div>
  );
};
