import { KeyOutlined } from "@ant-design/icons";
import { Form, Alert, Input, Button, message } from "antd";
import { useEffect, useState } from "react";
import { AuthWrapper } from "../AuthWrapper";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../../core/libs/api";
import { ROUTES } from "../../../core/routes";
import { AuthResponse, AuthStatusType } from "../../../core/types/auth";
import { BackToLogin } from "../BackToLogin";

type FormProps = {
  newPassword: string;
  confirmPassword: string;
};

const NewPasswordRequiredPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const session = new URLSearchParams(location.search).get("session");
  const email = new URLSearchParams(location.search).get("email");

  const [error, setError] = useState<string>();
  const [isLoading, setLoading] = useState<boolean>();

  useEffect(() => {
    if (!session || !email) {
      navigate(ROUTES.LOGIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, email]);

  const onFinish = async ({ confirmPassword, newPassword }: FormProps) => {
    setError(undefined);
    setLoading(true);

    if (confirmPassword !== newPassword) {
      setError("The passwords do not match!");
      setLoading(false);
      return;
    }

    await api
      .post<AuthResponse>("/auth/npr", {
        session,
        email,
        password: newPassword,
      })
      .then((resp) => {
        if (resp.status === AuthStatusType.MFA_SETUP) {
          const details = resp.result;
          navigate({
            pathname: ROUTES.MFA_SETUP,
            search: `?session=${details.session}&email=${details.email}&secretCode=${details.secretCode}`,
          });
        }

        if (resp.status === AuthStatusType.SUCCESS) {
          const details = resp.result;
          localStorage.setItem("accessToken", details.accessToken);
          localStorage.setItem("refreshToken", details.refreshToken);
          window.location.href = ROUTES.PROVIDERS;

          message.success("Account has been activated!");
        }

        if (resp.status === AuthStatusType.FAILURE) {
          setError(resp.message);
        }
      })
      .catch((err) => {
        setError(err?.message ?? "Unexpected error!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <AuthWrapper
      title="Change your password"
      subtitle="Your current password is only temporary. Set your own to use the
    platform."
    >
      <Form
        requiredMark={false}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        style={{ minWidth: 300 }}
        layout="vertical"
      >
        {error && (
          <Alert message={error} type="error" showIcon className="mb-5" />
        )}
        <Form.Item
          name="newPassword"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password
            prefix={<KeyOutlined className="site-form-item-icon" />}
            placeholder="New password"
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password
            prefix={<KeyOutlined className="site-form-item-icon" />}
            placeholder="Confirm password"
          />
        </Form.Item>

        <Form.Item>
          <Button
            loading={isLoading}
            type="primary"
            htmlType="submit"
            className="w-full mt-5"
          >
            Change password
          </Button>
          <BackToLogin />
        </Form.Item>
      </Form>
    </AuthWrapper>
  );
};

export default NewPasswordRequiredPage;
