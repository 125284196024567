import { useLocation, useNavigate } from "react-router-dom";
import { AuthWrapper } from "../AuthWrapper";
import QRCode from "qrcode.react";
import { Fragment } from "react/jsx-runtime";
import { Button, Form, Typography, Input, Alert } from "antd";
import { useEffect, useState } from "react";
import api from "../../../core/libs/api";
import { AuthResponse, AuthStatusType } from "../../../core/types/auth";
import { ROUTES } from "../../../core/routes";
import { BackToLogin } from "../BackToLogin";

const MfaSetupPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const navigate = useNavigate();

  const location = useLocation();
  const session = new URLSearchParams(location.search).get("session");
  const secretCode = new URLSearchParams(location.search).get("secretCode");
  const email = new URLSearchParams(location.search).get("email");

  useEffect(() => {
    if (!session || !email || !secretCode) {
      navigate(ROUTES.LOGIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, email, secretCode]);

  const onFinish = async (props: { totp: string }) => {
    setLoading(true);

    await api
      .post<AuthResponse>("/auth/mfa-setup", {
        session,
        totpCode: props.totp,
      })
      .then((response) => {
        if (response.status === AuthStatusType.SUCCESS) {
          navigate(ROUTES.LOGIN);
        } else {
          setError(response.message);
        }
      })
      .catch((err) => {
        setError(err?.message ?? "Unexpected error!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <AuthWrapper
      title="MFA Setup"
      subtitle="Scan the QR code using any authentication application on your phone (eq. Google Authenticator) to setup your two-factor authentication."
    >
      <Fragment>
        <div className="text-center">
          <QRCode
            value={`otpauth://totp/OneCred:${email}?secret=${secretCode}&issuer=OneCred`}
            size={96}
          />
        </div>

        <div className="pt-12">
          <Typography>
            Enter the 6 figure confirmation code shown on the app:
          </Typography>
          <Form className="pt-3" onFinish={onFinish}>
            <Form.Item
              name="totp"
              rules={[
                {
                  required: true,
                  message: "TOTP code is required!",
                },
              ]}
            >
              <Input.OTP autoFocus size="large" length={6} />
            </Form.Item>

            {error && (
              <Alert message={error} type="error" showIcon className="mb-5" />
            )}

            <Form.Item>
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                className="w-full mt-12"
              >
                Confirm
              </Button>
              <BackToLogin />
            </Form.Item>
          </Form>
        </div>
      </Fragment>
    </AuthWrapper>
  );
};

export default MfaSetupPage;
