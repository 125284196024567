import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, matchPath } from "react-router-dom";
import { auth } from "../../core/libs/api/auth";
import { PUBLIC_ROUTES, PRIVATE_ROUTES, ROUTES } from "../../core/routes";
import { Main } from "./Main";
import { User } from "../../core/types/auth";
import { PageLoading } from "../PageLoading";

interface Props {
  children: React.ReactElement;
}

const Layout = ({ children }: Props) => {
  const navigate = useNavigate();

  const [userData, setUserData] = useState<User | null>(null);
  const { pathname } = useLocation();
  const [loading, setLoading] = useState<boolean>(false);

  const isPublicRoute = PUBLIC_ROUTES.some((path) => matchPath(path, pathname));
  const isNotFoundRoute =
    !isPublicRoute && !PRIVATE_ROUTES.some((path) => matchPath(path, pathname));

  // const isAdminRoutes = ADMIN_ROUTES.some((path) => matchPath(path, pathname));

  useEffect(() => {
    const load = async () => {
      setLoading(true);

      await auth
        .currentUser()
        .then((response) => {
          setUserData(response as User);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    if (!isPublicRoute) {
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || (!userData && !isPublicRoute)) {
    return <PageLoading />;
  }

  // if (isAdminRoutes && !userData?.roles.includes(USER_ROLE.ADMIN)) {
  //   navigate(ROUTES.DASHBOARD);
  // }

  if (!userData && !isPublicRoute && !loading) {
    navigate(ROUTES.LOGIN);
  }

  if (isPublicRoute || isNotFoundRoute) {
    return children;
  }

  const content = React.cloneElement(children, { user: userData });

  return <Main user={userData}>{content}</Main>;
};

export default Layout;
