import { InboxOutlined, FileFilled } from "@ant-design/icons";
import { Alert, message } from "antd";
import { RcFile } from "antd/es/upload";
import Dragger from "antd/es/upload/Dragger";
import { Fragment } from "react/jsx-runtime";
import { StepWrapper } from "../StepWrapper";
import { StepsNavigation } from "../StepsNavigation";
import { useContext } from "react";
import { ProcessStartContext } from "../StartProcessModal";
import { readSheetsInFile } from "../utils";

export const UploadRostersStep = () => {
  const { process, updateProcess, onPrev, clearError, setError, loading } =
    useContext(ProcessStartContext);

  const beforeUpload = (file: RcFile) => {
    const isExcel = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ].includes(file.type);

    if (!isExcel) {
      message.error(`${file.name} is not an Excel file`);
    }

    updateProcess((prevProcess) => ({
      ...prevProcess,
      uploadedFile: file,
    }));
  };

  const onRemove = () => {
    updateProcess((prevProcess) => ({
      ...prevProcess,
      uploadedFile: undefined,
    }));
  };

  const uploadProps = {
    beforeUpload: beforeUpload,
    onRemove: onRemove,
    accept:
      ".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  };

  const _onNext = async () => {
    loading(true);
    clearError();

    if (!process.uploadedFile) {
      setError("You have not uploaded the roster file.");
      loading(false);
      return;
    }

    await readSheetsInFile(process.uploadedFile)
      .then(({ sheets }) => {
        updateProcess((prevProcess) => ({
          ...prevProcess,
          sheets,
          currentStep: +1,
        }));
      })
      .finally(() => {
        loading(false);
      });
  };

  return (
    <StepWrapper
      title="Upload roster"
      description="Upload your xlsx file containing your provider rosters."
    >
      <Fragment>
        <Dragger {...uploadProps} showUploadList={false}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Dragger>
        {process.uploadedFile && (
          <Alert
            showIcon
            icon={<FileFilled />}
            message={process.uploadedFile.name}
            className="mt-5"
            type="success"
            closable
            onClose={onRemove}
          />
        )}
        <StepsNavigation onNext={() => _onNext()} onBack={() => onPrev()} />
      </Fragment>
    </StepWrapper>
  );
};
