import { ROUTES } from "../routes";

export const pathNames = {
  [ROUTES.PROVIDERS]: {
    queryKey: "provider-users",
    url: "/providers",
    params: {
      search: "",
    },
  },
  [ROUTES.LOCATIONS]: {
    queryKey: "location",
    url: "/locations",
    params: {
      search: "",
    },
  },
  [ROUTES.GROUPS]: {
    queryKey: "group",
    url: "/groups",
    params: {
      search: "",
    },
  },
};

export type PathNamesKey = keyof typeof pathNames;

export const isPathNameKey = (key: string): key is PathNamesKey => {
  return "/" + key.split("/")[1] in pathNames;
};

export const stringWithoutExtraChar = (word: string) => {
  return word.split("/")[1];
};
