import { message, Modal } from "antd";
import { FC } from "react";
import ReactJson, { InteractionProps } from "react-json-view";
import api from "../../../core/libs/api";
import { ProviderDataModel } from "../../../core/types/provider";
import { useParams } from "react-router-dom";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  content?: ProviderDataModel;
}

const IMMUTABLE_FIELDS = ["UUID", "_id", "objectType", "npi"];

export const ContentPreviewModal: FC<Props> = ({
  isOpen,
  onClose,
  content,
}) => {
  const { id } = useParams();
  const onUpdate = async (props: InteractionProps) => {
    if (!props) {
      return;
    }

    if (IMMUTABLE_FIELDS.includes(props.name as never)) {
      message.error(`${props.name} field cannot be edited.`);
      return;
    }

    const updatedSrc = props.updated_src as ProviderDataModel;
    if (updatedSrc) {
      await api
        .put(`/provider/${id}`, updatedSrc)
        .then(() => {
          message.info("Updated!");
        })
        .catch(() => {
          message.error("Error. Try again alter.");
        });
    }
  };

  return (
    <Modal width={850} open={isOpen} onCancel={onClose} title="Content preview">
      <ReactJson
        onEdit={(edit) => onUpdate(edit)}
        displayDataTypes={false}
        displayObjectSize={false}
        src={content!}
        enableClipboard={false}
        shouldCollapse={(e) => e.name === "metadata"}
      />
    </Modal>
  );
};
