import {
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { useReactQuery } from "../../../../core/hooks/useReactQuery";
import {
  Group,
  GroupsQueryResponse,
  GroupType,
} from "../../../../core/types/provider-group";
import { QuestionCircleFilled } from "@ant-design/icons";
import { StepWrapper } from "../StepWrapper";
import { useContext, useState } from "react";
import { ProcessStartContext } from "../StartProcessModal";
import { StepsNavigation } from "../StepsNavigation";
import { useForm } from "antd/es/form/Form";
import api from "../../../../core/libs/api";
import { mapGroupName } from "../utils/mapper";

export const AssignGroupStep = () => {
  const [form] = useForm();
  const [isSaveGroup, setSaveGroup] = useState<boolean>(false);

  const {
    process,
    updateProcess,
    onNext,
    onPrev,
    setError,
    clearError,
    loading,
  } = useContext(ProcessStartContext);

  const { data: groups = [] } = useReactQuery<GroupsQueryResponse[]>({
    queryKey: ["groups"],
    url: "/groups",
  });

  const mapGroup = () =>
    groups.map((group) => ({
      label: group.name,
      value: group._id,
    }));

  const onSelectGroup = (group?: string) => {
    updateProcess((prevProcess) => ({
      ...prevProcess,
      selectedGroup: group ?? undefined,
    }));
  };

  const handleSaveGroup = async () => {
    loading(true);

    const _form = form.getFieldsValue();
    return await api
      .post<Group>("/groups", _form)
      .then((res) => {
        updateProcess((prevProcess) => ({
          ...prevProcess,
          selectedGroup: res._id,
          currentStep: prevProcess.currentStep + 1,
        }));
      })
      .catch((err: any) => {
        setError(err.message);
      })
      .finally(() => {
        loading(false);
      });
  };

  const _onNext = async () => {
    clearError();
    const _form = form.getFieldsValue();

    if (
      isSaveGroup &&
      !process.selectedGroup &&
      (!_form.name || !_form.groupType)
    ) {
      setError("Set the name and type of the group.");
      return;
    }

    if (isSaveGroup && !process.selectedGroup) {
      return await handleSaveGroup();
    } else {
      onNext();
      return;
    }
  };

  return (
    <StepWrapper
      title="Assign group"
      description="Assign the roster to the appropriate group if it exists or create a new one. (Optional)"
    >
      <div className="flex flex-col">
        <Space direction="vertical">
          <Typography.Text>Select group</Typography.Text>
          <Select
            allowClear
            placeholder="Select"
            className="min-w-[200px]"
            options={mapGroup()}
            defaultValue={process.selectedGroup}
            onClear={() => onSelectGroup(undefined)}
            onSelect={(group) => onSelectGroup(group)}
          />
        </Space>

        <Space className="mt-12" direction="vertical">
          <Checkbox
            defaultChecked={isSaveGroup}
            onChange={(e) => setSaveGroup(e.target.checked)}
          >
            <div className="w-full flex flex-row gap-x-2">
              <span className="select-none">
                If you didn't find the group you were looking for, you can
                always create a new one.
              </span>
              <Tooltip title="A new group will be created when you move on to the next stage.">
                <QuestionCircleFilled className="text-gray-500" />
              </Tooltip>
            </div>
          </Checkbox>
          {isSaveGroup && (
            <Card className="mt-5" title="Setup new group">
              <Form form={form} layout="vertical">
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Name"
                      name="name"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Type"
                      name="groupType"
                      rules={[{ required: true }]}
                    >
                      <Select
                        allowClear
                        options={Object.values(GroupType).map((group) => ({
                          label: mapGroupName[group],
                          value: group,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {/* <Row>
                  <Col span={24}>
                    <Form.Item label="Description" name="description">
                      <Input.TextArea rows={4} />
                    </Form.Item>
                  </Col>
                </Row> */}
              </Form>
            </Card>
          )}
        </Space>

        <StepsNavigation onNext={() => _onNext()} onBack={() => onPrev()} />
      </div>
    </StepWrapper>
  );
};
