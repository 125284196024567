import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { Button, Row } from "antd";
import { useParams } from "react-router-dom";
import api from "../../../core/libs/api";
import {
  ApprovalStatus,
  IngestionProcess,
  ProcessStatus,
} from "../../../core/types/ingestion-process";
import { TableOfChanges } from "./TableOfChanges";
import { useState } from "react";
import { ChangesPreviewModal } from "./ChangesPreviewModal";

export enum DiscrepancyType {
  PROVIDER = "provider",
  LOCATION = "location",
}

interface Props {
  process: IngestionProcess;
  refetch: () => void;
}
export const ApprovementSection = ({ process, refetch }: Props) => {
  const { id } = useParams();
  const [isPreview, setPreviewModal] = useState<boolean>(false);
  const [commitLoading, setCommitLoading] = useState<boolean>(false);
  const [discardLoading, setDiscardLoading] = useState<boolean>(false);

  const openPreviewModal = () => {
    setPreviewModal(true);
  };
  const closePreviewModal = () => setPreviewModal(false);

  const onSelectDecision = async (decision: ApprovalStatus) => {
    if (decision === ApprovalStatus.APPROVED) {
      setCommitLoading(true);
    } else {
      setDiscardLoading(true);
    }

    await api
      .post(`/ingestion-processes/approvement`, {
        processUUID: id,
        decision,
      })
      .then(() => {
        refetch();
      })
      .finally(() => {
        setCommitLoading(false);
        setDiscardLoading(false);
      });
  };

  console.log("process: ", process);

  const hasButtons =
    !process?.approvement?.approveStatus &&
    ![ProcessStatus.FINISHED_SUCCESS, ProcessStatus.FINISHED_ERROR].includes(
      process.status
    );

  return (
    <div className="flex flex-col">
      {hasButtons && (
        <div className="flex gap-x-2 mt-5">
          <Button
            loading={commitLoading}
            onClick={() => onSelectDecision(ApprovalStatus.APPROVED)}
            type="primary"
          >
            Commit changes
          </Button>
          <Button
            loading={discardLoading}
            onClick={() => onSelectDecision(ApprovalStatus.REJECTED)}
          >
            Discard changes
          </Button>
        </div>
      )}

      {process?.approvement?.approveStatus === ApprovalStatus.APPROVED && (
        <Row className="text-green-600 font-semibold mt-5 gap-x-2">
          <CheckCircleFilled />
          <span>Changes has been approved.</span>
        </Row>
      )}

      {process?.approvement?.approveStatus === ApprovalStatus.REJECTED && (
        <Row className="text-red-600 font-semibold mt-5 gap-x-2">
          <CloseCircleFilled />
          <span>Changes has been rejected.</span>
        </Row>
      )}

      <TableOfChanges openPreviewModal={openPreviewModal} process={process} />

      <ChangesPreviewModal
        isOpen={isPreview}
        onCancel={closePreviewModal}
        process={process}
      />
    </div>
  );
};
