import {
  LeftOutlined,
  RightOutlined,
  CloseOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { Button, message, Popconfirm } from "antd";
import { useContext } from "react";
import { ProcessStartContext } from "./StartProcessModal";
import { useNavigate } from "react-router-dom";
import api from "../../../core/libs/api";
import { initialProcess } from "./types";

interface Props {
  onBack: () => void;
  onNext: () => void;
}
export const StepsNavigation = ({ onBack, onNext }: Props) => {
  const { process, updateProcess, loading } = useContext(ProcessStartContext);
  const navigate = useNavigate();

  const onCancel = () => {
    updateProcess((prevProcess) => ({
      ...prevProcess,
      ...initialProcess,
    }));
  };

  const onConfirm = async () => {
    loading(true);

    try {
      const formData = new FormData();
      formData.append("file", process.uploadedFile!);

      formData.append(
        "details",
        JSON.stringify({
          layoutId: process.selectedTemplate,
          groupId: process?.selectedGroup,
          roster: {
            rosterIndex: process.rosterIndex,
            sheetIndex: process.sheetIndex,
          },
        })
      );

      await api.post<string>("/ingestion-processes", formData).then((resp) => {
        navigate(`/ingestions/${resp}`);
      });
    } catch (err) {
      console.log(err);
      message.error("Cannot start process. Please contact with administrator.");
    } finally {
      loading(false);
    }
  };

  return (
    <div className="flex flex-row justify-between gap-x-2 pt-12 w-full">
      <div>
        {process.currentStep !== 0 && (
          <Button icon={<LeftOutlined />} onClick={onBack} type="primary">
            Back
          </Button>
        )}
      </div>

      <div>
        {process.currentStep !== 0 && (
          <Popconfirm title="Are you sure?" onConfirm={onCancel}>
            <Button
              icon={<CloseOutlined />}
              className="bg-red-600 mr-5"
              type="primary"
            >
              Cancel
            </Button>
          </Popconfirm>
        )}

        {process.currentStep !== 4 && (
          <Button
            loading={process.loading}
            icon={<RightOutlined />}
            onClick={onNext}
            type="primary"
          >
            Next
          </Button>
        )}

        {process.currentStep === 4 && (
          <Button
            icon={<CheckOutlined />}
            onClick={onConfirm}
            className="bg-green-600"
            type="primary"
            loading={process.loading}
          >
            Start Process
          </Button>
        )}
      </div>
    </div>
  );
};
