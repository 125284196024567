interface Props {
  blob: Blob;
  filename: string;
}
export const handleBlobFile = ({ blob, filename }: Props) => {
  const url = window.URL.createObjectURL(new Blob([blob]));

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();

  link.parentNode?.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export const download = {
  handleBlobFile,
};
