export const personalInformationColumns = {
  name: {
    "First name": "personal_information.name.[0].first",
    "Middle name": "personal_information.name.[0].middle",
    "Last name": "personal_information.name.[0].last",
    Preferred: "personal_information.name.[0].preferred",
    "Used other names": "personal_information.name.[0].used_other_names",
  },
  homeAddress: {
    Street:
      "personal_information.contact_information.physical_address.[0].street.[0]",
    City: "personal_information.contact_information.physical_address.[0].city",
    State:
      "personal_information.contact_information.physical_address.[0].state",
    Zip: "personal_information.contact_information.physical_address.[0].zip",
    County:
      "personal_information.contact_information.physical_address.[0].county",
    Country:
      "personal_information.contact_information.physical_address.[0].country",
    "Suite or Number":
      "personal_information.contact_information.physical_address.[0].suite/number",
    Longitude:
      "personal_information.contact_information.physical_address.[0].longitude",
    Latitude:
      "personal_information.contact_information.physical_address.[0].latitude",
  },
  mailingAddress: {
    Street:
      "personal_information.contact_information.mailing_address.[0].street.[0]",
    City: "personal_information.contact_information.mailing_address.[0].city",
    State: "personal_information.contact_information.mailing_address.[0].state",
    Zip: "personal_information.contact_information.mailing_address.[0].zip",
    County:
      "personal_information.contact_information.mailing_address.[0].county",
    Country:
      "personal_information.contact_information.mailing_address.[0].country",
    "Suite or Number":
      "personal_information.contact_information.mailing_address.[0].suite/number",
  },
  demographics: {
    "Birth date": "personal_information.demographics.birth_date.[0].birth_date",
    "Birth city": "personal_information.demographics.birth_city.[0].birth_city",
    "Birth country":
      "personal_information.demographics.birth_country.[0].birth_country",
    "Birth state":
      "personal_information.demographics.birth_state.[0].birth_state",
    "Race/ethnicity": "personal_information.demographics.race/ethnicity.[0]",
    Gender:
      "personal_information.demographics.gender_identity.[0].gender_identity",
  },
  other: {
    Email: "personal_information.contact_information.email.[0].email.[0].email",
    Fax: "personal_information.contact_information.fax.[0].fax.[0]",
    Phone:
      "personal_information.contact_information.phone.[0].phone.[0].number",
    SSN: "personal_information.ssn.[0].number",
    "Held UPIN": "personal_information.UPIN.[0].held",
    UPIN: "personal_information.UPIN.[0].UPIN",
    NPI: "personal_information.npi.[0].npi",
  },
};

export const pinColumns = {
  license: {
    State:
      "professional_identification_numbers.professional_licenses.[0].license_state",
    Number:
      "professional_identification_numbers.professional_licenses.[0].license_number",
    Status:
      "professional_identification_numbers.professional_licenses.[0].license_status",
    Type: "professional_identification_numbers.professional_licenses.[0].license_type",
    "Expiration date":
      "professional_identification_numbers.professional_licenses.[0].expiration_date",
    "Issue date":
      "professional_identification_numbers.professional_licenses.[0].issue_date",
    "License entity":
      "professional_identification_numbers.professional_licenses.[0].license_entity",
  },
  medicare: {
    Participating:
      "professional_identification_numbers.medicare.[0].participating",
    Number: "professional_identification_numbers.medicare.[0].medicare_provider_number",
    State: "professional_identification_numbers.medicare.[0].state",
  },
  medicaid: {
    Participating:
      "professional_identification_numbers.medicaid.[0].participating",
    Number: "professional_identification_numbers.medicaid.[0].medicaid_id_number",
    State: "professional_identification_numbers.medicaid.[0].state",
  },
  other: {
    "DEA Registration":
      "professional_identification_numbers.dea_registration.[0].held",
    "Dangerous substance Registration":
      "professional_identification_numbers.controlled_dangerous_substance_registration.[0].held",
    "Hold ECFMG number":
      "professional_identification_numbers.ecfmg.[0].hold_number",
  },
};

export const educationColumns = {
  professional: {
    Name: "education.professional_school_information.[0].name",
    Phone: "education.professional_school_information.[0].contact_information.phone.[0].phone.[0].number",
    Fax: "education.professional_school_information.[0].contact_information.fax.[0].fax.[0]",
    Email: "education.professional_school_information.[0].contact_information.email.[0].email.[0].email",
    Degree: "education.professional_school_information.[0].degree",
    "Start date": "education.professional_school_information.[0].start_date",
    "End date": "education.professional_school_information.[0].end_date",
    "Area of training":
      "education.professional_school_information.[0].area_of_training",
    Complete: "education.professional_school_information.[0].complete",
    "Graduation date":
      "education.professional_school_information.[0].graduation_date",
  },
  undergraduate: {
    Name: "education.undergraduate_education.[0].name",
    Phone: "education.undergraduate_education.[0].contact_information.phone.[0].phone.[0].number",
    Fax: "education.undergraduate_education.[0].contact_information.fax.[0].fax.[0]",
    Email: "education.undergraduate_education.[0].contact_information.email.[0].email.[0].email",
    Degree: "education.undergraduate_education.[0].degree",
    "Start date": "education.undergraduate_education.[0].start_date",
    "End date": "education.undergraduate_education.[0].end_date",
    "Area of training":
      "education.undergraduate_education.[0].area_of_training",
    Complete: "education.undergraduate_education.[0].complete",
    "Graduation date": "education.undergraduate_education.[0].graduation_date",
  },
  graduate: {
    Name: "education.graduate_education.[0].name",
    Phone: "education.graduate_education.[0].contact_information.phone.[0].phone.[0].number",
    Fax: "education.graduate_education.[0].contact_information.fax.[0].fax.[0]",
    Email: "education.graduate_education.[0].contact_information.email.[0].email.[0].email",
    Degree: "education.graduate_education.[0].degree",
    "Start date": "education.graduate_education.[0].start_date",
    "End date": "education.graduate_education.[0].end_date",
    "Area of training":
      "education.graduate_education.[0].area_of_training",
    Complete: "education.graduate_education.[0].complete",
    "Graduation date": "education.graduate_education.[0].graduation_date",
  }
};

export const trainingColumns = {
  internship: {
    Type: "training.internships.[0].type",
    "Institution name": "training.internships.[0].institution_name",
    Email: "training.internships.[0].contact_information.email.[0].email.[0].email",
    Phone: "training.internships.[0].contact_information.phone.[0].phone.[0].number",
    "Start date": "training.internships.[0].start_date",
    "End date": "training.internships.[0].end_date",
    Department: "training.internships.[0].department",
    Specialty: "training.internships.[0].specialty",
    Complete: "training.internships.[0].complete",
    "Completion date": "training.internships.[0].completion_date",
  },
  other: {
    "Cultural competency training": `training.cultural_competency_training.[0].complete`,
  },
};
