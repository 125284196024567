import { Space } from "antd";

import { UsersTable } from "./UsersTable";
// import { ValidationApiTable } from "./ValidationApiTable";
import Typography from "antd/es/typography/Typography";
import { ExportTemplateTable } from "./ExportTemplatesTable";

const AdminPage = () => {
  return (
    <Space direction="vertical" className="w-full">
      <Typography className="font-semibold text-2xl">Admin Panel</Typography>
      <UsersTable />
      <ExportTemplateTable />
      {/* <ValidationApiTable /> */}
    </Space>
  );
};

export default AdminPage;
