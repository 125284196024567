import { LeftOutlined } from "@ant-design/icons";

export const BackToLogin = () => {
  return (
    <div className="text-center text-xs bottom-0justify-center pt-5">
      <a href="/auth/login" className="text-black align-baseline ">
        <div className="text-center bottom-0 flex flex-row gap-x-1 justify-center pt-5 cursor-pointer">
          <LeftOutlined />
          <span>Back to login.</span>
        </div>
      </a>
    </div>
  );
};
