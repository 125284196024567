import React from "react";
import { User } from "../types/auth";

type ContextType = {
  user: User;
};

const initialConfigs: ContextType = {
  user: {} as any,
};

export const AppContext = React.createContext<ContextType>(initialConfigs);

interface AppContextProviderProps {
  user: User;
  children?: React.ReactNode;
}

export const AppContextProvider = ({
  children,
  user,
}: AppContextProviderProps) => {
  return (
    <AppContext.Provider
      value={{
        user,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
