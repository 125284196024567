import { Space, Typography, Row, Col, Checkbox } from "antd";

export const ColumnsSection = ({
  title,
  columns = {},
  onSelect,
  selectedFields,
}: {
  title: string;
  columns: Record<string, string>;
  onSelect: (key: string) => void;
  selectedFields: string[];
}) => {
  const isSelected = (val: string) => !!selectedFields.find((e) => e === val);
  return (
    <Space direction="vertical" className="mb-5">
      <Typography.Text className="font-semibold">{title}</Typography.Text>
      <Row gutter={18}>
        {Object.entries(columns).map(([key, value]) => (
          <Col className="gutter-row mb-5" key={key}>
            <Space
              className="cursor-pointer"
              direction="horizontal"
              onClick={() => onSelect(value)}
            >
              <Checkbox checked={isSelected(value)} />
              <Typography.Text className="whitespace-nowrap">{key}</Typography.Text>
            </Space>
          </Col>
        ))}
      </Row>
    </Space>
  );
};
