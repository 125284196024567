import { WorkHistory } from "../../../../core/types/provider";
import { FieldResponse, Props } from "../provider-fields";

export const work_history = ({
  payload,
}: Props<WorkHistory>): FieldResponse[] => [
  {
    label: "Name of the Employer",
    value: payload["practice/employer"],
  },
  {
    label: "Type of Activity",
    value: payload.activity_type,
  },
  {
    label: "Title",
    value: payload.title,
  },
  {
    label: "Department",
    value: payload.department,
  },
  {
    label: "Start Date",
    value: payload.start_date,
  },
  {
    label: "End Date",
    value: payload.end_date,
  },
  {
    label: "Clinical Time",
    value: payload.clinical_time,
  },
  {
    label: "Administrative Time",
    value: payload.administrative_time,
  },
  {
    label: "Reason for Departure",
    value: payload.reason_for_departure,
  },
  {
    label: "Non-educational staff",
    value: payload.on_staff_non_educational,
  },
  {
    label: "Patient Privileges",
    value: payload.patient_privileges,
  },
  {
    label: "In Training",
    value: payload.in_training,
  },
  {
    label: "Verification Contact",
    value: payload.verification_contact,
  },
];
