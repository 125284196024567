import { HealthStatus } from "../../../../core/types/provider";
import { FieldResponse, Props } from "../provider-fields";

export const health_status = ({
  payload,
}: Props<HealthStatus>): FieldResponse[] => [
  {
    label: "Addiction",
    value: payload.addiction,
  },
  {
    label: "Rehabilitation",
    value: payload.rehabilitation,
  },
  {
    label: "Explanation",
    value: payload.explanation,
  },
  {
    label: "Impairment",
    value: payload.impairment,
  },
  {
    label: "Can Fulfill Responsibility",
    value: payload.can_fulfill_responsibility,
  },
  {
    label: "Reasonable Accommodation Required",
    value: payload.reasonable_accommodation_required,
  },
  {
    label: "Pose a Risk to the Safty of Patients",
    value: payload.patient_safety,
  },
];
