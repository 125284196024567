import { conditionClass, joinClasses } from "../core/utils/classes.utils";

interface Props {
  label: string;
  value: string | number | boolean;
  layout?: "horizontal" | "vertical";
}
export const FormField = ({ label, value, layout = "vertical" }: Props) => {
  return (
    <div
      className={joinClasses(
        "flex",
        conditionClass(
          layout === "horizontal",
          "flex-row items-center",
          "flex-col"
        )
      )}
    >
      <span className="font-semibold uppercase">{label}</span>
      <div
        className={joinClasses(
          "mt-1 text-md",
          conditionClass(layout === "horizontal", "pl-2")
        )}
      >
        {value}
      </div>
    </div>
  );
};
