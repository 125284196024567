import { Form, Input, Alert, Button } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthWrapper } from "../AuthWrapper";
import { AuthResponse, AuthStatusType } from "../../../core/types/auth";
import api from "../../../core/libs/api";
import { ROUTES } from "../../../core/routes";
import { BackToLogin } from "../BackToLogin";

const TotpTokenInput = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const location = useLocation();
  const navigate = useNavigate();

  const session = new URLSearchParams(location.search).get("session");
  const email = new URLSearchParams(location.search).get("email");

  useEffect(() => {
    if (!session || !email) {
      navigate(ROUTES.LOGIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, email]);

  const onFinish = async (props: { totp: string }) => {
    setLoading(true);

    await api
      .post<AuthResponse>("/auth/two-factor", {
        session,
        email,
        totpCode: props.totp,
      })
      .then((response) => {
        if (response.status === AuthStatusType.SUCCESS) {
          const details = response.result;
          localStorage.setItem("accessToken", details.accessToken);
          localStorage.setItem("refreshToken", details.refreshToken);
          window.location.href = ROUTES.PROVIDERS;
        } else {
          setError(response.message);
        }
      })
      .catch((err) => {
        setError(err?.message ?? "Unexpected error!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <AuthWrapper
      title="Two-factor authentication"
      subtitle="Please enter the six digit code from your authenticator app."
    >
      <Form className="pt-3" onFinish={onFinish}>
        <Form.Item
          name="totp"
          rules={[
            {
              required: true,
              message: "TOTP code is required!",
            },
          ]}
        >
          <Input.OTP autoFocus size="large" length={6} />
        </Form.Item>

        {error && (
          <Alert message={error} type="error" showIcon className="mb-5" />
        )}

        <Form.Item>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            className="w-full mt-12"
          >
            Confirm
          </Button>
          <BackToLogin />
        </Form.Item>
      </Form>
    </AuthWrapper>
  );
};

export default TotpTokenInput;
