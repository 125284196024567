interface PillProps {
  icon: JSX.Element;
  title: string;
  value: string | number;
}
export const DetailPill = ({ icon, title, value }: PillProps) => {
  return (
    <div className="bg-white flex items-center p-4 border border-solid border-gray-200 rounded-full w-full">
      <div className="rounded-full p-3 bg-gray-100">{icon}</div>
      <div className="pl-3">
        <div className="text-gray-500 text-sm">{title}</div>
        <div className="text-xl font-semibold">{value}</div>
      </div>
    </div>
  );
};
