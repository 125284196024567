import { Certificate } from "../../../../core/types/provider";
import { FieldResponse, Props } from "../provider-fields";

export const certificate = ({
  payload,
}: Props<Certificate>): FieldResponse[] => {
  return [
    {
      label: "Certified",
      value: payload.certified,
    },
    {
      label: "Date",
      value: payload.date,
    },
    {
      label: "Issuer",
      value: payload.issuer,
    },
    {
      label: "Expiration Date",
      value: payload.expiration_date,
    },
  ];
};
