import { Typography } from "antd";

interface Props {
  title: string;
  description?: string;
  children: JSX.Element;
}
export const StepWrapper = ({ title, description, children }: Props) => {
  return (
    <div className="flex flex-col gap-y-5 p-5">
      <div className="flex flex-col">
        <Typography.Text className="font-bold text-3xl pb-2">
          {title}
        </Typography.Text>
        <span>{description}</span>
      </div>

      <div className="mt-5">{children}</div>
    </div>
  );
};
