import { Modal } from "antd";
import { createContext, FC, useState } from "react";
import { ProcessSteps } from "./ProcessSteps";
import { initialProcess, ProcessDetails } from "./types";

type ContextType = {
  process: ProcessDetails;
  updateProcess: (process: React.SetStateAction<ProcessDetails>) => void;
  onNext: () => void;
  onPrev: () => void;
  setError: (message: string) => void;
  clearError: () => void;
  loading: (val: boolean) => void;
};

export const ProcessStartContext = createContext<ContextType>({
  onNext: () => {},
  onPrev: () => {},
  setError: () => {},
  clearError: () => {},
  process: initialProcess,
  updateProcess: () => {},
  loading: () => {},
});

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const StartProcessModal: FC<Props> = ({ isOpen, onClose }) => {
  const [process, updateProcess] = useState<ProcessDetails>(initialProcess);

  const navigationProps = {
    onNext: () => {
      updateProcess((prevProcess) => ({
        ...prevProcess,
        currentStep: prevProcess.currentStep + 1,
      }));
    },
    onPrev: () => {
      updateProcess((prevProcess) => ({
        ...prevProcess,
        currentStep: prevProcess.currentStep - 1,
      }));
    },
  };

  const setError = (message: string) => {
    updateProcess((prevProcess) => ({
      ...prevProcess,
      errorMessage: message,
    }));
  };

  const clearError = () => {
    updateProcess((prevProcess) => ({
      ...prevProcess,
      errorMessage: "",
    }));
  };

  const _onClose = () => {
    updateProcess((prevProcess) => ({
      ...prevProcess,
      ...initialProcess,
    }));
    onClose();
  };

  const loading = (isLoading: boolean) => {
    updateProcess((prevProcess) => ({
      ...prevProcess,
      loading: isLoading,
    }));
  };

  return (
    <Modal
      width={1250}
      open={isOpen}
      onCancel={_onClose}
      maskClosable={false}
      title="Start ingestion process"
      footer={null}
    >
      <ProcessStartContext.Provider
        value={{
          process,
          updateProcess,
          ...navigationProps,
          setError,
          clearError,
          loading,
        }}
      >
        <ProcessSteps />
      </ProcessStartContext.Provider>
    </Modal>
  );
};
