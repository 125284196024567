import { ProviderDataModel } from "../../../core/types/provider";
import {
  affiliations,
  discipline,
  education_school,
  education_school_address,
  health_status,
  malpractice,
  military_service,
  personal_information_demographics,
  personal_information_mailing_address,
  personal_information_name,
  personal_information_other,
  personal_information_physical_address,
  pin_licenses,
  pin_medicaid,
  pin_medicare,
  pin_others,
  specialty,
  training_internship,
  training_internship_address,
  training_others,
  work_history,
} from "./provider-fields";
import { certificate } from "./provider-fields/certification";

export const getSections = (provider: ProviderDataModel) => ({
  "Personal Information": [
    {
      title: "Name",
      data: provider.personal_information?.name,
      dataMapper: personal_information_name,
    },
    {
      title: "Physical address",
      data: provider.personal_information.contact_information.physical_address,
      dataMapper: personal_information_physical_address,
    },
    {
      title: "Mailing address",
      data: provider.personal_information.contact_information.mailing_address,
      dataMapper: personal_information_mailing_address,
    },
    {
      title: "Demographics",
      data: [provider.personal_information.demographics],
      dataMapper: personal_information_demographics,
    },
    {
      title: "Others",
      data: [provider.personal_information],
      dataMapper: personal_information_other,
    },
  ],
  "Personal identification numbers": [
    {
      title: "License",
      data: provider.professional_identification_numbers.professional_licenses,
      dataMapper: pin_licenses,
    },
    {
      title: "Medicare",
      data: provider.professional_identification_numbers.medicare,
      dataMapper: pin_medicare,
    },
    {
      title: "Medicaid",
      data: provider.professional_identification_numbers.medicaid,
      dataMapper: pin_medicaid,
    },
    {
      title: "Others",
      data: [provider.professional_identification_numbers],
      dataMapper: pin_others,
    },
  ],
  Education: [
    {
      title: "Professional School",
      data: provider.education.professional_school_information,
      dataMapper: (props: any) =>
        education_school({ ...props, school: "Professional School" }),
    },
    {
      title: "Professional School Address",
      data: provider.education.professional_school_information,
      dataMapper: (props: any) =>
        education_school_address({
          ...props,
          school: "professional_school_information",
        }),
    },
    {
      title: "Undergraduate School",
      data: provider.education.undergraduate_education,
      dataMapper: (props: any) =>
        education_school({ ...props, school: "undergraduate_education" }),
    },
    {
      title: "Undergraduate School Address",
      data: provider.education.undergraduate_education,
      dataMapper: (props: any) =>
        education_school_address({
          ...props,
          school: "undergraduate_education",
        }),
    },
  ],
  Training: [
    {
      title: "Internship",
      data: provider.training.internships,
      dataMapper: training_internship,
    },
    {
      title: "Address",
      data: provider.training.internships,
      dataMapper: training_internship_address,
    },
    {
      title: "Others",
      data: [provider.training],
      dataMapper: training_others,
    },
  ],
  Specialty: [
    {
      title: "Specialties",
      data: provider.specialty.specialties,
      dataMapper: specialty,
    },
  ],
  Certification: [
    {
      title: "CPR",
      data: [provider.certification.CPR],
      dataMapper: certificate,
    },
    {
      title: "BLS",
      data: [provider.certification.BLS],
      dataMapper: certificate,
    },
    {
      title: "ACLS",
      data: [provider.certification.ACLS],
      dataMapper: certificate,
    },
    {
      title: "ALSO",
      data: [provider.certification.ALSO],
      dataMapper: certificate,
    },
    {
      title: "Corec",
      data: [provider.certification.CoreC],
      dataMapper: certificate,
    },
    {
      title: "Atls",
      data: [provider.certification.ATLS],
      dataMapper: certificate,
    },
    {
      title: "Nals",
      data: [provider.certification.NALS],
      dataMapper: certificate,
    },
    {
      title: "Nrp",
      data: [provider.certification.NRP],
      dataMapper: certificate,
    },
    {
      title: "Pals",
      data: [provider.certification.PALS],
      dataMapper: certificate,
    },
    {
      title: "Other",
      data: [provider.certification.other],
      dataMapper: certificate,
    },
  ],
  Affiliation: [
    {
      title: "Affiliations",
      data: provider.affiliations,
      dataMapper: affiliations,
    },
  ],
  "Work History": [
    {
      title: "Work History",
      data: provider.work_history,
      dataMapper: work_history,
    },
  ],
  "Military Service": [
    {
      title: "Military",
      data: provider.military_service,
      dataMapper: military_service,
    },
  ],
  Malpractice: [
    {
      title: "Malpractice",
      data: provider.malpractice,
      dataMapper: malpractice,
    },
  ],
  "Health Status": [
    {
      title: "Health Status",
      data: provider.health_status,
      dataMapper: health_status,
    },
  ],
  Discipline: [
    {
      title: "Discipline",
      data: provider.discipline,
      dataMapper: discipline,
    },
  ],
});
