import { Roster } from "../../../../core/types/ingestion-process";

export const calculateTotalDiscrepancies = (rosters: Roster[]) => {
  let providersAdds = 0;
  let providersChanges = 0;
  let locationsAdds = 0;
  let locationsChanges = 0;

  // Loop over each roster object
  rosters.forEach((roster) => {
    // Count provider adds and changes
    if (roster.payload.provider) {
      providersAdds += roster.payload.provider.adds.length;
      providersChanges += roster.payload.provider.changes.length;
    }

    // Loop over locations in the roster to count adds and changes
    if (roster.payload.locations) {
      roster.payload.locations.forEach((location) => {
        locationsAdds += location.adds.length;
        locationsChanges += location.changes.length;
      });
    }
  });

  return {
    providersAdds,
    providersChanges,
    locationsAdds,
    locationsChanges,
  };
};
