import { Alert, Button, Form, Input } from "antd";
import { useState } from "react";
import api from "../../../core/libs/api";
import { ROUTES } from "../../../core/routes";
import { UserOutlined, KeyOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { AuthWrapper } from "../AuthWrapper";
import { useForm } from "antd/es/form/Form";
import { AuthResponse, AuthStatusType } from "../../../core/types/auth";

const LoginPage = () => {
  const [form] = useForm();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();

  const onFinish = async (form: { email: string; password: string }) => {
    setIsLoading(true);

    await api
      .post<AuthResponse>("/auth/login", form)
      .then((response) => {
        if (response.status === AuthStatusType.SUCCESS) {
          const details = response.result;
          localStorage.setItem("accessToken", details.accessToken);
          localStorage.setItem("refreshToken", details.refreshToken);
          window.location.href = ROUTES.PROVIDERS;
        }

        if (response.status === AuthStatusType.NEW_PASSWORD_REQUIRED) {
          const details = response.result;
          navigate({
            pathname: ROUTES.CHANGE_PASSWORD,
            search: `?session=${details.session}&email=${details.email}`,
          });
        }

        if (response.status === AuthStatusType.MFA_SETUP) {
          const details = response.result;
          navigate({
            pathname: ROUTES.MFA_SETUP,
            search: `?session=${details.session}&email=${details.email}&secretCode=${details.secretCode}`,
          });
        }

        if (response.status === AuthStatusType.SOFTWARE_TOKEN_MFA) {
          const details = response.result;
          navigate({
            pathname: ROUTES.TWO_FACTOR,
            search: `?session=${details.session}&email=${details.email}`,
          });
        }
      })
      .catch((err) => {
        setError(err?.message ?? "Unexpected error!");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <AuthWrapper title="Log In" subtitle="Please log in to your account.">
      <Form
        form={form}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        requiredMark={false}
        layout="vertical"
        style={{ minWidth: 300 }}
      >
        {error && (
          <Alert message={error} type="error" showIcon className="mb-5" />
        )}
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
          ]}
        >
          <Input.Password
            prefix={<KeyOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item>
          <Button
            loading={isLoading}
            type="primary"
            htmlType="submit"
            className="w-full mt-5"
          >
            Log in
          </Button>
        </Form.Item>

        <div className="text-center bottom-0">
          <span className="text-xs">
            You do not have an account? Please contact your administrator to
            create them for you.
          </span>
        </div>
      </Form>
    </AuthWrapper>
  );
};

export default LoginPage;
