import { SearchButtonType } from "../core/types/search-bar";

export const SearchButton = ({
  onClick,
  icon: Icon,
  label,
  className,
}: SearchButtonType) => {
  return (
    <div
      className={`py-2 w-1/2 border-none transition-all duration-200 justify-center cursor-pointer rounded-sm flex flex-row items-center gap-x-2 ${className}`}
      onClick={onClick}
    >
      <Icon />
      <span>{label}</span>
    </div>
  );
};
