import { ExclamationCircleFilled } from "@ant-design/icons";
import { Steps } from "antd";
import { Fragment, useContext, useState } from "react";
import { AssignGroupStep } from "./steps/AssignGroupStep";
import { MapRostersStep } from "./steps/MapRostersStep";
import { RosterDetailsStep } from "./steps/RosterDetailsStep";
import { SummaryStep } from "./steps/SummaryStep";
import { UploadRostersStep } from "./steps/UploadRostersStep";
import { StepData } from "./types";
import { ProcessStartContext } from "./StartProcessModal";

export const ProcessSteps = () => {
  const { process } = useContext(ProcessStartContext);

  const [steps] = useState<StepData[]>([
    {
      title: "Upload file",
      content: <UploadRostersStep />,
    },
    {
      title: "Roster details",
      content: <RosterDetailsStep />,
    },
    {
      title: "Map columns",
      content: <MapRostersStep />,
    },
    {
      title: "Assign group",
      content: <AssignGroupStep />,
    },
    {
      title: "Summary",
      content: <SummaryStep />,
    },
  ]);

  return (
    <Fragment>
      <div className="p-5 mt-5">
        <Steps progressDot current={process.currentStep} items={steps} />
      </div>

      {process.errorMessage && (
        <div className="my-5 px-3 py-2 flex flex-row gap-x-2 bg-red-200 rounded text-red-900">
          <ExclamationCircleFilled />
          <span>{process.errorMessage}</span>
        </div>
      )}

      {steps[process.currentStep]?.content}
    </Fragment>
  );
};
