import { Specialty } from "../../../../core/types/provider";
import { FieldResponse, Props } from "../provider-fields";

export const specialty = ({
  payload,
  index,
}: Props<Specialty>): FieldResponse[] => [
  {
    label: "Name",
    value: payload.name,
  },
  {
    label: "Board Certified",
    value: payload.board_certified,
  },
  {
    label: "Board Issues",
    value: payload.issuing_board,
  },
  {
    label: "Date Issued",
    value: payload.date_issued,
  },
  {
    label: "Expiration Date",
    value: payload.expiration_date,
  },
  {
    label: "Recertification Date",
    value: payload.recertification_date,
  },
  {
    label: "Exam Date",
    value: payload.exam_date,
  },
  {
    label: "Explanation Why Not Applying for Certificate",
    value: payload.explanation_why_not_applying_for_cert,
  },
  {
    label: "State",
    value: payload.state,
  },
  {
    label: "Failed Certification Exam",
    value: payload.failed_certification_exam,
  },
];
