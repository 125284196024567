import { Table } from "antd";
import { RosterColumnType } from "../types";
import { AnyObject } from "antd/es/_util/type";
import { ExclamationCircleFilled } from "@ant-design/icons";

interface Props {
  columns: RosterColumnType[];
  dataSource: AnyObject[];
  mappings: Map<string, any>;
}
export const SummaryRosterTable = ({
  columns,
  dataSource,
  mappings,
}: Props) => {
  const prepareColumns = () =>
    columns.map((column) => {
      const title = mappings.get(column.dataIndex)?.label ?? (
        <ExclamationCircleFilled className="text-red-500" />
      );

      return {
        title,
        ...column,
      };
    });

  return (
    <Table
      className="mt-5"
      scroll={{ x: 1300 }}
      columns={prepareColumns()}
      dataSource={dataSource}
      pagination={{
        pageSize: 5,
      }}
    />
  );
};
