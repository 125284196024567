import { useParams } from "react-router-dom";
import { useReactQuery } from "../../core/hooks/useReactQuery";
import { Alert, Card, Col, Row, Table, Typography } from "antd";
import { PageLoading } from "../../components/PageLoading";
import { SUMMARY_TABLE_COLUMNS } from "./utils";
import {
  IngestionProcess,
  ProcessStep,
  StepStatus,
} from "../../core/types/ingestion-process";
import { CustomCollapse } from "./start-process/components/CustomCollapse";
import { ProcessDetails } from "./components/ProcessDetails";
import ReactJson from "react-json-view";
import { ApprovementSection } from "./components/ApprovementSection";
import { RostersTable } from "./components/RostersTable";

const IngestionProcessPage = () => {
  const { id } = useParams();

  const {
    data: process,
    refetch,
    isRefetching,
  } = useReactQuery<IngestionProcess>({
    queryKey: [`process_${id}`],
    url: `/ingestion-processes/${id}`,
  });

  if (!process) {
    return <PageLoading />;
  }

  const SUMMARY_TABLE_ROWS = Object.entries(process.steps).map(
    ([key, value]) => ({
      step: key,
      notes: value.notes?.[0]?.message ?? "",
      status: value.status,
      finishedAt: value.finishedAt,
      startedAt: value.startedAt,
    })
  );

  return (
    <div className="w-full">
      <ProcessDetails
        isReloading={isRefetching}
        reloadProcess={refetch}
        process={process}
      />
      {process.errors.length > 0 && (
        <Card className="mt-4" title="Processing errors">
          {process.errors.map((error, key) => (
            <Alert
              showIcon
              type="error"
              message={
                typeof error.message === "string"
                  ? error.message
                  : "Internal error. Please contact with administrator."
              }
              description={
                <span className="text-xs font-semibold">
                  {process.executionArn}
                </span>
              }
              key={key}
            />
          ))}
        </Card>
      )}

      {(process.rosters?.length ?? 0) > 0 && (
        <CustomCollapse className="mt-4" header="Rosters">
          <RostersTable
            rosters={process?.rosters ?? []}
            expand={(record) => (
              <ReactJson
                name={null}
                shouldCollapse={(e) => ["metadata"].includes(e.name ?? "")}
                displayDataTypes={false}
                displayObjectSize={false}
                src={{
                  provider: record.payload.provider.content,
                  locations: record.payload.locations.map(
                    ({ content }) => content
                  ),
                }}
                enableClipboard={false}
              />
            )}
          />
        </CustomCollapse>
      )}

      <Row className="w-full flex flex-1 pt-4" gutter={16}>
        <Col span={16}>
          <Card title="Load Summary">
            <Table
              dataSource={SUMMARY_TABLE_ROWS}
              columns={SUMMARY_TABLE_COLUMNS}
              bordered
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Adds, Changes & Terms">
            <Typography.Text>
              This are providers detailed information on what adds, changes and
              terms will be applied to the current data for providers once this
              roster is loaded. It is important to review these adds, changes
              and terms in detail, to ensure they meet the expected outcome. If
              you do not want to make these changes, decline the roster
              ingestion.
            </Typography.Text>

            {process.steps[ProcessStep.DETECTING_CHANGES].status ===
              StepStatus.FINISHED_SUCCESS && (
              <ApprovementSection process={process} refetch={refetch} />
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default IngestionProcessPage;
