import "./styles/main.css";

import { QueryCache, QueryClient, QueryClientProvider } from "react-query";
import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { getAppRoutes } from "./core/routes";
import Layout from "./components/Layouts";
import { PageLoading } from "./components/PageLoading";

const queryCache = new QueryCache();
const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const App = () => {
  const renderRoutes = () => {
    return (
      <Suspense fallback={<PageLoading />}>
        <Routes>
          {getAppRoutes().map((route) => (
            <Route
              path={route.path}
              key={route.path}
              element={
                <Layout>
                  <route.component />
                </Layout>
              }
            />
          ))}
        </Routes>
      </Suspense>
    );
  };

  return (
    <div className="App">
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          {renderRoutes()}
        </QueryClientProvider>
      </BrowserRouter>
    </div>
  );
};

export default App;
