import { Alert, Select, Space } from "antd";
import { FC, useState } from "react";
import { useReactQuery } from "../../../core/hooks/useReactQuery";
import { ExportTemplateType } from "../../../core/types/export-template";
import { ExportModal } from "../../../components/ExportModal";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  selectedProviders: string[];
}

export const ConfirmBatchExportModal: FC<Props> = ({
  isOpen,
  onClose,
  selectedProviders = [],
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState<string>();

  const { data: templates = [], isLoading } = useReactQuery<
    ExportTemplateType[]
  >({
    queryKey: ["export-templates"],
    url: "/data-export/templates",
  });

  const onCancel = () => {
    setSelectedTemplate(undefined);
    onClose();
  };

  return (
    <ExportModal
      isOpen={isOpen}
      onClose={onCancel}
      apiProps={{
        templateId: selectedTemplate,
        providers: selectedProviders,
      }}
      content={
        <Space direction="vertical" className="w-full mt-5">
          <Alert
            showIcon
            message="Select the export template defined in the admin panel."
            type="info"
          />

          <Select
            placeholder="Select export template"
            loading={isLoading}
            onSelect={(e) => setSelectedTemplate(e)}
            className="w-full"
          >
            {templates.map((template, index) => (
              <Select.Option key={index} value={template._id}>
                {template.name}
              </Select.Option>
            ))}
          </Select>
        </Space>
      }
    />
  );
};
