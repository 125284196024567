import {
  EducationInfo,
  Internship,
  Training,
} from "../../../../core/types/provider";
import { FieldResponse, Props } from "../provider-fields";

export const training_internship = ({ payload, index }: Props<Internship>) => [
  {
    label: "Type",
    value: payload.type,
  },
  {
    label: "Institution name",
    value: payload.institution_name,
  },
  {
    label: "Email",
    value: payload.contact_information?.email[0]?.email[0]?.email,
  },
  {
    label: "Phone",
    value: payload.contact_information?.phone[0]?.phone[0]?.number,
  },
  {
    label: "Start date",
    value: payload.start_date,
  },
  {
    label: "End date",
    value: payload.end_date,
  },
  {
    label: "Department",
    value: payload.department,
  },
  {
    label: "Specialty",
    value: payload.specialty,
  },
  {
    label: "Complete",
    value: payload.complete,
  },
  {
    label: "Completion date",
    value: payload.completion_date,
  },
];

export const training_internship_address = ({
  payload,
  index,
}: Props<EducationInfo>): FieldResponse[] => [
  {
    label: "Street",
    value: payload.contact_information?.physical_address[0]?.street,
  },
  {
    label: "City",
    value: payload.contact_information?.physical_address[0]?.city,
  },
  {
    label: "State",
    value: payload.contact_information?.physical_address[0]?.state,
  },
  {
    label: "ZIP",
    value: payload.contact_information?.physical_address[0]?.zip,
  },
  {
    label: "County",
    value: payload.contact_information?.physical_address[0]?.county,
  },
  {
    label: "Country",
    value: payload.contact_information?.physical_address[0]?.country,
  },
];

export const training_others = ({
  payload,
}: Props<Training>): FieldResponse[] => [
  {
    label: "Cultural competency training",
    value: payload.cultural_competency_training[0]?.complete,
  },
];
