import { UserOutlined } from "@ant-design/icons";
import { Avatar, Card, Row, Space, Typography } from "antd";
import { ProviderInfo } from "../types/Provider";
import { renderColumns } from "../utils/provider-data.utils";

export const ProviderMainInfo = ({ provider }: ProviderInfo) => {
  const columns = renderColumns(provider);

  const personal_information = provider?.personal_information;
  const gender =
    personal_information?.demographics?.gender_identity?.[0]?.gender_identity;
  const name = personal_information?.name[0];
  const npi = personal_information?.npi[0]?.npi;

  return (
    <Row className="w-full justify-between my-4">
      <Card className="flex flex-col flex-1 shadow-sm h-min-full">
        <Space direction="vertical" className="gap-x-3 ml-2 flex flex-row">
          <Avatar shape="square" size={64} icon={<UserOutlined />} />
          <div className="flex flex-col">
            <Typography.Text className="text-xl font-semibold text-sky-500">
              {name?.first} {name?.last}
            </Typography.Text>
            <Typography.Text style={{ display: "flex" }}>
              <div className="font-semibold mr-1 uppercase">Npi:</div> {npi}
            </Typography.Text>
            <Typography.Text className="flex item-center text-center uppercase text-xs">
              <div className="font-semibold mr-1">Gender:</div>
              {gender}
            </Typography.Text>
          </div>
        </Space>
      </Card>
      <Card
        className="w-3/5 border border-solid border-gray-100 rounded-lg ml-4 shadow-sm rounded-sm"
        styles={{ body: { padding: 0 } }}
      >
        <div className="flex p-6 justify-between border-none flex-wrap">
          {columns.map((column, index) => (
            <div className="w-[80px]" key={index}>
              <div className="font-semibold text-xs">{column.title}</div>
              <div className="mt-1 text-md">{column.row}</div>
            </div>
          ))}
        </div>
        <div className="w-full flex flex-col border-top ">
          <div className="p-6">
            <div className="uppercase font-semibold text-xs">
              Languages Spoken
            </div>
            {/* TODO: get this value from data model! */}
            <div className="mt-1 text-md">English</div>
          </div>
        </div>
      </Card>
    </Row>
  );
};
