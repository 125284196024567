import { Discipline } from "../../../../core/types/provider";
import { FieldResponse, Props } from "../provider-fields";

export const discipline = ({ payload }: Props<Discipline>): FieldResponse[] => [
  {
    label: "Denial of License",
    value: payload.denial_of_license,
  },
  {
    label: "Denial of Dea Registration",
    value: payload.denial_of_dea_registration,
  },
  {
    label: "Academic Appointment Denied",
    value: payload.academic_appointment_denied,
  },
  {
    label: "Hospital Membership Denied",
    value: payload.hospital_membership_denied,
  },
  {
    label: "Clinical Privileges Denied",
    value: payload.clinical_privileges_denied,
  },
  {
    label: "Resignation to Avoid Discipline",
    value: payload.resignation_to_avoid_discipline,
  },
  {
    label: "Board Certification Denied",
    value: payload.board_certification_denied,
  },
  {
    label: "Felony Conviction",
    value: payload.felony_conviction,
  },
  {
    label: "Professional Action",
    value: payload.professional_action,
  },
  {
    label: "Insurance Investigation",
    value: payload.insurance_investigation,
  },
  {
    label: "Medicare Medicaid Explusion",
    value: payload.medicare_medicaid_explusion,
  },
  {
    label: "Medicaid Opt Out",
    value: payload.medicaid_opt_out,
  },
  {
    label: "Medicare Opt Out",
    value: payload.medicare_opt_out,
  },
];
