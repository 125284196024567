import {
  Address,
  Demographics,
  PersonalInformation,
  PhysicalAddress,
} from "../../../../core/types/provider";
import { FieldResponse, Props } from "../provider-fields";

export const personal_information_name = ({
  payload,
}: Props): FieldResponse[] => [
  {
    label: "First name",
    value: payload.first,
  },
  {
    label: "Middle name",
    value: payload.middle,
  },
  {
    label: "Last name",
    value: payload.last,
  },
  {
    label: "Preferred",
    value: payload.preferred,
  },
  {
    label: "Used other names",
    value: payload.used_other_names,
  },
];

export const personal_information_physical_address = ({
  payload,
}: Props<PhysicalAddress>): FieldResponse[] => [
  {
    label: "Street",
    value: payload.street[0],
  },
  {
    label: "City",
    value: payload.city,
  },
  {
    label: "Suite/number",
    value: payload["suite/number"],
  },
  {
    label: "State",
    value: payload.state,
  },
  {
    label: "ZIP",
    value: payload.zip,
  },
  {
    label: "County",
    value: payload.county,
  },
  {
    label: "Country",
    value: payload.country,
  },
  {
    label: "Latitude",
    value: payload.latitude,
  },
  {
    label: "Longitude",
    value: payload.longitude,
  },
];

export const personal_information_mailing_address = ({
  payload,
}: Props<Address>): FieldResponse[] => [
  {
    label: "Street",
    value: payload.street[0],
  },
  {
    label: "City",
    value: payload.city,
  },
  {
    label: "State",
    value: payload["suite/number"],
  },
  {
    label: "State",
    value: payload.state,
  },
  {
    label: "ZIP",
    value: payload.zip,
  },
  {
    label: "County",
    value: payload.county,
  },
  {
    label: "Country",
    value: payload.country,
  },
];

export const personal_information_demographics = ({
  payload,
}: Props<Demographics>): FieldResponse[] => [
  {
    label: "Birth Date",
    value: payload.birth_date[0]?.birth_date,
  },
  {
    label: "Birth City",
    value: payload.birth_city[0]?.birth_city,
  },
  {
    label: "Birth Country",
    value: payload.birth_country[0]?.birth_country,
  },
  {
    label: "Birth State",
    value: payload.birth_state[0]?.birth_state,
  },
  {
    label: "Gender",
    value: payload.gender_identity[0]?.gender_identity,
  },
  {
    label: "Race/ethnicity",
    value: payload["race/ethnicity"][0],
  },
];

export const personal_information_other = ({
  payload,
}: Props<PersonalInformation>): FieldResponse[] => {
  const response = [];
  const emails = payload.contact_information.email[0].email;
  for (let i = 0; i < emails.length; i++) {
    const data = payload.contact_information.email[0].email[i];
    response.push({
      label: `Email ${i > 0 ? `#${i + 1}` : ""}`,
      value: data?.email,
    });
  }

  for (let i = 0; i < payload.ssn.length; i++) {
    const data = payload.ssn[i];
    response.push({
      label: `SSN ${i > 0 ? `#${i + 1}` : ""}`,
      value: data?.number,
    });
  }

  for (let i = 0; i < payload.UPIN.length; i++) {
    const data = payload.UPIN[i];
    response.push({
      label: `UPIN ${i > 0 ? `#${i + 1}` : ""}`,
      value: data?.UPIN,
    });
  }

  for (let i = 0; i < payload.npi.length; i++) {
    const data = payload.npi[i];
    response.push({
      label: `NPI ${i > 0 ? `#${i + 1}` : ""}`,
      value: data?.npi,
    });
  }

  for (let i = 0; i < payload.fnin.length; i++) {
    const data = payload.fnin[i];
    response.push({
      label: `FNIN number ${i > 0 ? `#${i + 1}` : ""}`,
      value: data?.number,
    });

    response.push({
      label: `FNIN country ${i > 0 ? `#${i + 1}` : ""}`,
      value: data?.country,
    });

    response.push({
      label: `FNIN country ${i > 0 ? `#${i + 1}` : ""}`,
      value: data?.type,
    });
  }

  const methodOfContact: Record<number, string> = {
    0: "Email",
    1: "Phone",
    2: "SMS",
  };

  response.push({
    label: "Primary method of contact",
    value:
      methodOfContact[payload?.contact_information.primary_method_of_contact],
  });

  response.push({
    label: "US citizen",
    value:
      methodOfContact[payload?.contact_information.primary_method_of_contact],
  });

  return response;
};
