import {
  Alert,
  Button,
  Card,
  Form,
  Input,
  message,
  Space,
  Typography,
} from "antd";
import { User } from "../../core/types/auth";
import api from "../../core/libs/api";
import { useState } from "react";

type UpdatePasswordProps = {
  password: string;
  newPassword: string;
};

type UpdateUser = {
  firstName: string;
  lastName: string;
  contact: {
    email: string;
    phone: string;
  };
};
const SettingsPage = ({ user }: { user: User }) => {
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
  const [loadingPasswordUpdate, setLoadingPasswordUpdate] =
    useState<boolean>(false);

  const [updateError, setUpdateError] = useState<string>();
  const [updatePasswordError, setUpdatePasswordError] = useState<string>();

  const onUpdate = async (props: UpdateUser) => {
    setLoadingUpdate(true);
    await api
      .put("/users", props)
      .then(() => {
        message.success("Your details has been updated.");
        window.location.reload();
      })
      .catch((err: Error) => {
        setUpdateError(err.message);
      })
      .finally(() => {
        setLoadingUpdate(false);
      });
  };

  const onUpdatePassword = async (props: UpdatePasswordProps) => {
    setLoadingPasswordUpdate(true);
    await api
      .post("/auth/update-password", props)
      .then(() => {
        message.success("Password updated.");
        window.location.href = "/auth/login";
      })
      .catch((err: Error) => {
        setUpdatePasswordError(err.message);
      })
      .finally(() => {
        setLoadingPasswordUpdate(false);
      });
  };

  return (
    <Space className="w-full" direction="vertical">
      <Typography.Text className="text-[24px] font-semibold">
        Settings
      </Typography.Text>

      <Card title="Details" className="mt-5">
        <div className="w-full flex flex-row">
          <div className="w-1/2">
            <Typography>
              Remember to always keep your data up to date.
            </Typography>
          </div>
          <div className="w-1/2">
            <Form onFinish={onUpdate} initialValues={user} layout="vertical">
              <Form.Item
                label="First name"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Required field",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Last name"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Required field",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="Email" name={["contact", "email"]}>
                <Input disabled />
              </Form.Item>
              <Form.Item label="Phone" name={["contact", "phone"]}>
                <Input />
              </Form.Item>

              {updateError && (
                <Alert
                  message={updateError}
                  type="error"
                  showIcon
                  className="mb-5"
                />
              )}

              <Button loading={loadingUpdate} type="primary" htmlType="submit">
                Update
              </Button>
            </Form>
          </div>
        </div>
      </Card>
      <Card title="Security">
        <div className="w-full flex flex-row">
          <div className="w-1/2">
            <Typography>Update your OneCred password.</Typography>
          </div>
          <div className="w-1/2">
            <Form onFinish={onUpdatePassword} layout="vertical">
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Required field",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label="New Password"
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: "Required field",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              {updatePasswordError && (
                <Alert
                  message={updatePasswordError}
                  type="error"
                  showIcon
                  className="mb-5"
                />
              )}

              <Button
                loading={loadingPasswordUpdate}
                type="primary"
                htmlType="submit"
              >
                Update
              </Button>
            </Form>
          </div>
        </div>
      </Card>
    </Space>
  );
};

export default SettingsPage;
