import { Card, Space, Typography } from "antd";
import { GroupsTable } from "./GroupsTable";

export const GroupsPage = () => {
  return (
    <Space className="w-full" direction="vertical">
      <Typography.Text className="text-[24px] font-semibold text-sky-500">
        Groups
      </Typography.Text>
      <Card className="mt-5">
        <GroupsTable />
      </Card>
    </Space>
  );
};
