import { InputNumber, Radio, Space, Typography } from "antd";
import { StepWrapper } from "../StepWrapper";
import { StepsNavigation } from "../StepsNavigation";
import { ProcessStartContext } from "../StartProcessModal";
import { useContext } from "react";
import api from "../../../../core/libs/api";
import { generate } from "short-uuid";

export const RosterDetailsStep = () => {
  const { process, updateProcess, onPrev, setError, clearError, loading } =
    useContext(ProcessStartContext);

  const onEnterSheetIndex = (index: number) => {
    updateProcess((prevProcess) => ({
      ...prevProcess,
      sheetIndex: index,
    }));
  };

  const onEnterRosterRow = (index: number) => {
    updateProcess((prevProcess) => ({
      ...prevProcess,
      rosterIndex: index,
    }));
  };

  const handleUploadedFile = async () => {
    clearError();
    loading(true);

    const formData = new FormData();
    formData.append("file", process.uploadedFile as any);

    await api
      .post<string[][]>("/ingestion-processes/upload", formData, {
        params: {
          rosterIndex: process.rosterIndex,
          sheetIndex: process.sheetIndex,
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((rosters) => {
        if (rosters.length > 0) {
          const rosterFieldsCount = rosters[0].length;
          const columns = [...Array(rosterFieldsCount).keys()].map((val) => ({
            dataIndex: val,
            uuid: generate(),
          })) as any;
          updateProcess((prevProcess) => ({
            ...prevProcess,
            tableCols: columns,
            tableRows: rosters,
            currentStep: process.currentStep + 1,
          }));
        }
      })
      .catch((err) => {
        console.log("err: ", err);
        setError(
          "There is a problem with parsing your roster file. Try again later or upload new file."
        );
      })
      .finally(() => {
        loading(false);
      });
  };

  const _onNext = async () => {
    clearError();

    if (!process.rosterIndex) {
      setError(
        "You have to enter the index of your first roster in uploaded file."
      );
      return;
    }

    await handleUploadedFile();
  };

  return (
    <StepWrapper
      title="Roster details"
      description="We need to know which sheet to use in this process and know where the first line with the roster is located."
    >
      <div className="flex flex-col gap-y-12">
        <Space direction="vertical">
          {process.sheets.length > 1 ? (
            <Typography.Text>
              We found {process.sheets.length ?? 0} sheets in the uploaded file.
              Choose which sheet we should use.
            </Typography.Text>
          ) : (
            <Typography.Text>
              We found a single sheet in you file. We will use them in the
              further process.
            </Typography.Text>
          )}

          <Radio.Group
            value={process.sheetIndex}
            onChange={(event) => onEnterSheetIndex(event.target.value)}
            buttonStyle="solid"
          >
            {process.sheets.map((value, key) => (
              <Radio.Button key={key} value={key}>
                {value}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Space>

        <Space direction="vertical">
          <Typography.Text>
            Enter the number of the first line with the roster in your sheet.
          </Typography.Text>
          <InputNumber
            className="min-w-[120px]"
            value={process.rosterIndex}
            min={0}
            onChange={(event) => onEnterRosterRow(event as number)}
          />
        </Space>

        <StepsNavigation onNext={() => _onNext()} onBack={() => onPrev()} />
      </div>
    </StepWrapper>
  );
};
