import { Affiliation } from "../../../../core/types/provider";
import { FieldResponse, Props } from "../provider-fields";

export const affiliations = ({
  payload,
  index,
}: Props<Affiliation>): FieldResponse[] => [
  {
    label: "Admitting Arrangements",
    value: payload.admitting_arrangements,
  },
  {
    label: "Hospital Admitting Privileges",
    value: payload.hospital_admitting_privileges,
  },
  {
    label: "Name of the Facility",
    value: payload.facility_name,
  },
  {
    label: "Contact Information",
    value: [],
  },
  {
    label: "Name of the Department",
    value: payload.department_name,
  },
  {
    label: "Clinical Supervisor",
    value: payload.clinical_supervisor,
  },
  {
    label: "Start Date",
    value: payload.start_date,
  },
  {
    label: "End Date",
    value: payload.end_date,
  },
  {
    label: "Unrestricted Privileges",
    value: payload.full_unrestricted_privileges,
  },
  {
    label: "Temporary",
    value: payload.temporary,
  },
  {
    label: "Privilege Status",
    value: [],
  },
  {
    label: "Percentage",
    value: payload.percentage,
  },
];
