interface SummaryFieldProps {
  label: string;
  value?: string | number;
}
export const SummaryField = ({ label, value }: SummaryFieldProps) => {
  return (
    <div className="flex flex-row gap-x-2">
      <span>{label}:</span>
      <span className="font-bold">{value}</span>
    </div>
  );
};
