import { Card, Row } from "antd";
import { DataCardType } from "../types/Provider";
import { renderProviderDataForm } from "../utils/provider-data.utils";
import Typography from "antd/es/typography/Typography";

export const DataCard = ({
  title,
  data,
  dataMapper,
  onSelectField,
}: DataCardType) => {
  return (
    <Card title={title} className="mb-5">
      <Row gutter={16} className="text-c">
        {data.length === 0 ? (
          <Typography className="pl-2 text-xs text-center w-full">
            Data not provided
          </Typography>
        ) : (
          data.map((item, index) =>
            dataMapper({ index, payload: item }).map(
              renderProviderDataForm(onSelectField)
            )
          )
        )}
      </Row>
    </Card>
  );
};
