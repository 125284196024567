import { Menu, MenuProps } from "antd";
import Sider from "antd/es/layout/Sider";
import { useNavigate } from "react-router-dom";
import { SIDEBAR_ITEMS_DATA } from "../../core/configs/sidebar-config";
import { sidebarItems } from "../../core/utils/sidebar.utils";

export const SideBar = () => {
  const navigate = useNavigate();

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    const findPath = (key: string) => {
      const item = SIDEBAR_ITEMS_DATA.find((parent) => {
        if (parent.children) {
          return parent.children.some((child) => child.routeKey === key);
        }

        return parent.routeKey === key || parent.label === key;
      });
      if (item) {
        return (
          item.path ||
          item.children?.find((child) => child.routeKey === key)?.path
        );
      }
    };

    const path = findPath(e.key);
    if (path) {
      navigate(path);
    }
  };

  return (
    <Sider className="bg-white border-right" width={225}>
      <Menu
        mode="inline"
        defaultSelectedKeys={["1"]}
        defaultActiveFirst={true}
        defaultOpenKeys={["Providers"]}
        style={{ height: "100%" }}
        className="bg-white"
        items={sidebarItems}
        onClick={handleMenuClick}
      />
    </Sider>
  );
};
