import { RightOutlined, DownOutlined } from "@ant-design/icons";
import { Row } from "antd";
import { useState } from "react";
import { Fragment } from "react/jsx-runtime";
import { joinClasses } from "../../../../core/utils/classes.utils";
import Typography from "antd/es/typography/Typography";

interface CollapseItemProps {
  header: string | JSX.Element;
  children: JSX.Element;
  className?: string;
}
export const CustomCollapse = ({
  children,
  header,
  className = "",
}: CollapseItemProps) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);

  const icon = collapsed ? <RightOutlined /> : <DownOutlined />;

  return (
    <Fragment>
      <div
        onClick={() => setCollapsed(!collapsed)}
        className={joinClasses(
          "text-sm items-center flex flex-row justify-between p-5 px-6 border border-solid border-gray-200 cursor-pointer rounded-t-md bg-white",
          className
        )}
      >
        <Row className="items-center gap-x-2 font-semibold">
          <span className="text-xs">{icon}</span>
          <Typography>{header}</Typography>
        </Row>
      </div>
      {!collapsed && (
        <div
          className={joinClasses(
            "py-3 px-4 text-start border border-solid border-gray-200 rounded-b-md border-t-0 bg-white"
          )}
        >
          {children}
        </div>
      )}
    </Fragment>
  );
};
