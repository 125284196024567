import Axios, { AxiosRequestConfig } from "axios";

export const configureApi = () => {
  Axios.interceptors.response.use(
    (response) => {
      return response?.data;
    },
    (error) => {
      console.log("err: ", error);
      // message.error(error.response.data.message);

      if (error.response?.status === 401) {
        window.location.href = "/auth/login";
        // return error.response?.data;
      }

      return Promise.reject(error.response?.data);
    }
  );

  Axios.interceptors.request.use((config) => {
    config.headers.setAuthorization(localStorage.getItem("accessToken"));

    return {
      baseURL: process.env.REACT_APP_API_URL,
      withCredentials: true,
      maxRedirects: 0,
      ...config,
    };
  });
};

const get = <T>(
  url: string,
  params?: any,
  config?: AxiosRequestConfig
): Promise<T> => Axios.get(url, { ...config, params });
const post = <T>(
  url: string,
  params?: any,
  config?: AxiosRequestConfig
): Promise<T> => Axios.post(url, params, config);
const put = (url: string, params: any) => Axios.put(url, params);
const patch = <T>(url: string, params: any): Promise<T> =>
  Axios.patch(url, params);
const _delete = <T>(url: string, params?: any): Promise<T> =>
  Axios.delete(url, { params });

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get,
  post,
  put,
  patch,
  delete: _delete,
};
