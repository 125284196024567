import { Checkbox, List } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../core/routes";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { SearchBarListType } from "../../../core/types/search-bar";
import { conditionClass, joinClasses } from "../../../core/utils/classes.utils";
import { SearchButton } from "../../SearchButton";

type ProviderListItem = {
  _id: string; //document uuid
  uuid: string; //user uuid
  first: string;
  last: string;
  npi: number;
};

/**
 * TODO: This component should be only for providers, we need to create a reusable component for locations and groups lists as well
 */
export const SearchBarList = ({
  data,
  isLoading,
  isRefetching,
  isExport,
  setSelectedProviders,
  setExportModal,
  selectedProviders,
  onCancelExportModal,
}: SearchBarListType<ProviderListItem>) => {
  const [activeElement, setActiveElement] = useState<string>();
  const navigate = useNavigate();
  const location = useLocation();

  const showElementDetail = (elementId: string) => {
    setActiveElement(elementId);
    navigate(`/professionals/${elementId}`);
  };

  useEffect(() => {
    if (ROUTES.PROVIDERS === location.pathname) {
      setActiveElement(undefined);
    }
  }, [location.pathname]);

  const onCheckboxChange = (providerId: string, isChecked: boolean) => {
    const newSelectedProviders = isChecked
      ? [...selectedProviders, providerId]
      : selectedProviders.filter((id) => id !== providerId);

    setSelectedProviders(newSelectedProviders);
  };

  return (
    <div className="relative h-full">
      <List
        itemLayout="horizontal"
        dataSource={data ?? []}
        className="cursor-pointer pb-[50px]"
        loading={isLoading || isRefetching}
        renderItem={(item: ProviderListItem) => (
          <List.Item
            onClick={() => showElementDetail(item.uuid)}
            className={joinClasses(
              "hover:bg-gray-50 px-7 py-3 hover:text-sky-500",
              conditionClass(
                item.uuid === activeElement,
                "bg-gray-50 text-sky-500",
                "transparent"
              )
            )}
            extra={
              isExport && (
                <Checkbox
                  checked={selectedProviders.includes(item.uuid)}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onCheckboxChange(item.uuid, e.target.checked);
                  }}
                />
              )
            }
          >
            <div className="flex flex-col">
              <span className="font-semibold text-[15px]">
                {item.first} {item.last}
              </span>
              <span className="text-xs">NPI: {item.npi}</span>
            </div>
          </List.Item>
        )}
      />
      {isExport && (
        <div className="fixed bottom-0 w-full max-w-[325px] flex border-none">
          <SearchButton
            onClick={() => setExportModal(true)}
            icon={CheckOutlined}
            label="Confirm"
            className="bg-blue-500 hover:bg-blue-600 text-white"
          />
          <SearchButton
            onClick={() => onCancelExportModal()}
            icon={CloseOutlined}
            label="Cancel"
            className="bg-gray-200 hover:bg-gray-300 text-black"
          />
        </div>
      )}
    </div>
  );
};
