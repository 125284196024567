import { Table } from "antd";
import { useState } from "react";
import { Roster } from "../../../core/types/ingestion-process";
import { ROSTERS_TABLE_COLUMNS } from "../utils";
import { CaretRightOutlined } from "@ant-design/icons";

interface Props {
  rosters: Roster[];
  expand: (roster: Roster) => JSX.Element;
  columns?: (page: number) => {}[];
}

const enhanceDataWithKeys = (data: any[]) => {
  return data.map((item, index) => ({
    ...item,
    key: `item-${index}`, // Using the index to generate a unique key
  }));
};

export const RostersTable = ({
  rosters,
  expand,
  columns = undefined,
}: Props) => {
  const [rostersPage, setRostersPage] = useState<number>(1);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const onExpand = (expanded: boolean, record: any) => {
    const keys = expanded ? [record.key] : [];
    setExpandedRowKeys(keys as []);
  };

  return (
    <Table
      className="w-full"
      dataSource={enhanceDataWithKeys(rosters || [])}
      pagination={{
        showSizeChanger: false,
        onChange(current) {
          setRostersPage(current);
        },
      }}
      expandable={{
        expandRowByClick: true,
        expandedRowRender: (record: Roster) => expand(record),
        rowExpandable: () => true,
        expandedRowKeys,
        expandIcon: ({ expanded }) => (
          <CaretRightOutlined rotate={expanded ? 90 : 0} />
        ),
        onExpand,
      }}
      columns={
        (columns && columns(rostersPage)) ??
        ROSTERS_TABLE_COLUMNS({ page: rostersPage })
      }
      bordered
    />
  );
};
