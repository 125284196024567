import { Fragment } from "react/jsx-runtime";
import dateUtils from "../../../../core/utils/date.utils";
import { useContext } from "react";
import { AppContext } from "../../../../core/contexts/AppContextProvider";
import { SummaryRosterTable } from "../components/SummaryRosterTable";
import { useReactQuery } from "../../../../core/hooks/useReactQuery";
import { GroupsQueryResponse } from "../../../../core/types/provider-group";
import { SummaryField } from "../../components/SummaryField";
import { StepWrapper } from "../StepWrapper";
import { ProcessStartContext } from "../StartProcessModal";
import { StepsNavigation } from "../StepsNavigation";

export const SummaryStep = () => {
  const { process, onNext, onPrev } = useContext(ProcessStartContext);
  const { user } = useContext(AppContext);
  const { data: groups = [] } = useReactQuery<GroupsQueryResponse[]>({
    queryKey: ["groups"],
    url: "/groups",
  });

  const getSelectedGroup = () => {
    const group = groups.find((e) => e._id === process.selectedGroup);
    return group?.name;
  };

  return (
    <StepWrapper
      title="Summary"
      description="Check all the information before starting the process."
    >
      <Fragment>
        <div className="flex flex-col">
          <SummaryField label="File Name" value={process.uploadedFile?.name} />
          <SummaryField
            label="Uploaded at"
            value={dateUtils.formatDate(
              dateUtils.toUnix(),
              "M/DD/YYYY HH:mm a"
            )}
          />
          <SummaryField label="Uploaded by" value={user.contact.email} />
          <SummaryField label="Approval Status" value="Waiting to start" />
          <SummaryField
            label="Uploaded rosters"
            value={process.tableRows?.length ?? 0}
          />
          <SummaryField
            label="Mapped roster fields"
            value={process.mappings.size}
          />
          <SummaryField label="Selected group" value={getSelectedGroup()} />
        </div>

        <SummaryRosterTable
          columns={process.tableCols}
          dataSource={process.tableRows}
          mappings={process.mappings}
        />

        <StepsNavigation onNext={() => onNext()} onBack={() => onPrev()} />
      </Fragment>
    </StepWrapper>
  );
};
