import { Card, Space, Typography } from "antd";
import { LocationsTable } from "./LocationsTable";

export const LocationsPage = () => {
  return (
    <Space className="w-full" direction="vertical">
      <Typography.Text className="text-[24px] font-semibold text-sky-500">
        Location
      </Typography.Text>
      <Card className="mt-5">
        <LocationsTable />
      </Card>
    </Space>
  );
};
