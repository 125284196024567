import {
  ExperimentOutlined,
  HomeOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { ROUTES } from "../routes";

export const SIDEBAR_ITEMS_DATA = [
  {
    label: "Providers",
    icon: UserOutlined,
    routeKey: "providers",
    children: [
      {
        label: "Professionals",
        path: ROUTES.PROVIDERS,
        routeKey: "professionals",
      },
      { label: "Locations", path: ROUTES.LOCATIONS, routeKey: "locations" },
      { label: "Groups", path: ROUTES.GROUPS, routeKey: "groups" },
    ],
  },
  {
    label: "Ingestions Process",
    icon: ExperimentOutlined,
    path: ROUTES.INGESTIONS,
    routeKey: "ingestions",
  },
  {
    label: "Admin Panel",
    icon: HomeOutlined,
    path: ROUTES.ADMIN_PANEL,
    routeKey: "admin",
  },
];
