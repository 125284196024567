import {
  Row,
  Input,
  Select,
  Space,
  Table,
  Tooltip,
  TreeSelect,
  Typography,
  Form,
  Switch,
  Radio,
  Collapse,
} from "antd";
import {
  DeleteOutlined,
  ExclamationCircleFilled,
  InfoCircleFilled,
  QuestionCircleFilled,
} from "@ant-design/icons";
import { RosterColumnType } from "../types";
import { processTreeNodes } from "../utils";
import { useReactQuery } from "../../../../core/hooks/useReactQuery";
import {
  Mapping,
  MappingTemplate,
} from "../../../../core/types/ingestion-process";
import { DefaultOptionType } from "antd/es/select";
import { useContext, useEffect, useState } from "react";
import FormItem from "antd/es/form/FormItem";
import { ProcessStartContext } from "../StartProcessModal";
import { StepsNavigation } from "../StepsNavigation";
import { useForm } from "antd/es/form/Form";
import api from "../../../../core/libs/api";
import { StepWrapper } from "../StepWrapper";
import { MappingInformations } from "../components/MappingInformations";

enum OPTION {
  UPDATE,
  CREATE_NEW,
}

type HeaderType = {
  treeData: any;
  _key: string;
  val: RosterColumnType;
};

export const MapRostersStep = () => {
  const [optionOnUpdate, setOptionOnUpdate] = useState<OPTION>(OPTION.UPDATE);

  const {
    process,
    updateProcess,
    onNext,
    onPrev,
    setError,
    clearError,
    loading,
  } = useContext(ProcessStartContext);

  const [form] = useForm();

  const [isEditable, setEditable] = useState<boolean>(false);
  const { data: templates = [], refetch: refetchTemplates } = useReactQuery<
    MappingTemplate[]
  >({
    queryKey: ["layout-mappings"],
    url: "/layout-mappings",
  });

  const { data: schemaTree, isRefetching: isRefetchingSchema } = useReactQuery({
    queryKey: ["schema-tree"],
    url: "/schema/roster-tree",
    options: {
      keepPreviousData: true,
    },
  });

  const onUpdateMapping = (map: Map<any, any>) => {
    updateProcess((prevProcess) => ({
      ...prevProcess,
      mappings: map,
    }));
  };

  const onSelectTemplate = (template?: string) => {
    updateProcess((prevProcess) => ({
      ...prevProcess,
      selectedTemplate: template,
    }));
  };

  const onRemoveColumn = (uuid: string) => {
    const columns = process.tableCols.filter((e) => e.uuid !== uuid);
    const newMappings = new Map(process.mappings);
    newMappings.delete(uuid);

    updateProcess((prevProcess) => ({
      ...prevProcess,
      tableCols: columns,
      mappings: newMappings,
    }));
  };

  useEffect(() => {
    if (process.selectedTemplate) {
      const template = templates.find(
        (template) => template._id === process.selectedTemplate
      );

      const mappings = template?.mappings || [];
      const map = new Map();
      for (const mapping of mappings) {
        map.set(mapping.columnIndex, {
          key: mapping.key,
          label: mapping.label,
        });
      }
      onUpdateMapping(map);
    } else {
      onUpdateMapping(new Map());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [process.selectedTemplate]);

  const onMapColumn = (
    key: string,
    details: { title: string; value: string },
    { dataIndex }: RosterColumnType
  ) => {
    onUpdateMapping(
      new Map(process.mappings).set(dataIndex, {
        key,
        label: details.title,
      })
    );
  };

  const onClear = (key?: string) => {
    const mapping = Array.from(process.mappings.entries()).find(
      ([_, value]) => value.key === key
    );
    if (mapping) {
      const map = new Map(process.mappings);
      map.delete(mapping?.[0]);
      onUpdateMapping(map);
    } else {
      console.error(`Cannot remove mapping. Key: ${key} don't found`);
    }
  };

  const MapHeader = (props: HeaderType) => (
    <div className="flex flex-row items-center">
      <TreeSelect
        placeholder="Select field"
        disabled={!!process.selectedTemplate && !isEditable}
        className="min-w-[150px]"
        loading={isRefetchingSchema}
        showSearch
        allowClear
        onClear={() => onClear(props._key)}
        treeData={props.treeData}
        onSelect={(key, options: any) => onMapColumn(key, options, props.val)}
        defaultValue={props._key}
      />
      {(isEditable || !process.selectedTemplate) && (
        <Tooltip title="Delete column">
          <DeleteOutlined
            onClick={() => onRemoveColumn(props.val.uuid)}
            className="p-2 hover:bg-gray-100 rounded-full cursor-pointer"
          />
        </Tooltip>
      )}

      {props._key && (
        <Tooltip title={props?._key}>
          <QuestionCircleFilled className="p-2 hover:bg-gray-100 rounded-full cursor-pointer" />
        </Tooltip>
      )}
    </div>
  );

  const renderColumns = () => {
    const tableColumns: any[] = [];
    const treeData = processTreeNodes((schemaTree ?? []) as any);
    const tableCols = process.tableCols.map((val) => {
      const mapping = process.mappings.get(val.dataIndex);
      return {
        id: val.uuid,
        dataIndex: val.dataIndex,
        width: 200,
        title: <MapHeader _key={mapping?.key} treeData={treeData} val={val} />,
      };
    });
    tableColumns.push(...tableCols);

    return tableColumns;
  };

  const mapTemplatesToSelectOptions = (): DefaultOptionType[] =>
    templates?.map((template) => ({
      label: template.name,
      value: template._id,
    })) || [];

  const mapToTemplate = (mappings: Map<any, any>) => {
    const _mappings: Mapping[] = [];
    mappings.forEach((details, columnIndex) => {
      _mappings.push({
        columnIndex,
        key: details.key,
        label: details.label,
      });
    });

    return _mappings;
  };

  const handleSaveMappingTemplate = async () => {
    clearError();
    loading(true);

    const _mappings = mapToTemplate(process.mappings);

    const templateName = form.getFieldValue("templateName");
    await api
      .post<string>("/layout-mappings", {
        id:
          optionOnUpdate === OPTION.UPDATE
            ? process?.selectedTemplate
            : undefined,
        name: templateName,
        mappings: _mappings,
      })
      .then((res) => {
        onSelectTemplate(res);
        onNext();
      })
      .finally(() => {
        refetchTemplates();
        loading(false);
        form.resetFields();
      });
  };

  const _onNext = async () => {
    clearError();

    if (process.mappings.size === 0) {
      setError(
        "You must assign at least one column to the appropriate place in the data model."
      );
      return;
    }

    const notSavedLayout =
      process.mappings.size > 0 && !process.selectedTemplate;
    const templateName = form.getFieldValue("templateName");

    const isError =
      (notSavedLayout && !templateName) ||
      (optionOnUpdate === OPTION.CREATE_NEW && !templateName);

    if (isError) {
      setError("You need to save current layout as template to start process.");
      return;
    }

    const shouldSubmit =
      (notSavedLayout && templateName) ||
      (isEditable && optionOnUpdate === OPTION.UPDATE) ||
      (isEditable && templateName && optionOnUpdate === OPTION.CREATE_NEW);

    if (shouldSubmit) {
      return await handleSaveMappingTemplate();
    }

    if (process.selectedTemplate) {
      onNext();
    }
  };

  return (
    <StepWrapper
      title="Map columns"
      description="All columns to participate in the process must be assigned to the appropriate place in the data model. Columns that are not assigned or has been deleted will not be included in the process. Select existing mapping template or create a new one."
    >
      <div className="w-full flex flex-col">
        <Collapse
          ghost
          items={[
            {
              label: "Mapping informations",
              headerClass: "p-0",
              children: <MappingInformations />,
            },
          ]}
        />
        <Row className="w-full gap-x-12 pt-12">
          <Space direction="vertical" className="mb-5">
            <Typography.Text>Mapping Template</Typography.Text>
            <Select
              allowClear={true}
              defaultValue={process.selectedTemplate}
              options={mapTemplatesToSelectOptions()}
              onClear={() => onSelectTemplate(undefined)}
              onSelect={(template) => onSelectTemplate(template)}
              className="min-w-[200px]"
            />
          </Space>
          {process.selectedTemplate && (
            <Space direction="vertical" className="mb-5">
              <Typography.Text>Edit Template</Typography.Text>
              <Switch
                defaultChecked={isEditable}
                onChange={(checked) => setEditable(checked)}
              />
            </Space>
          )}
        </Row>

        <Table
          loading={process.loading}
          scroll={{ x: 1300 }}
          columns={renderColumns()}
          dataSource={process.tableRows}
          bordered
          pagination={{
            showTotal: (total) => `Uploaded ${total} rosters`,
            pageSize: 5,
          }}
        />

        {isEditable && (
          <Radio.Group
            defaultValue={optionOnUpdate}
            onChange={(e) => setOptionOnUpdate(e.target.value)}
          >
            <Space direction="vertical">
              <Radio value={OPTION.UPDATE}>
                Update current mapping template
                <Tooltip title="The template update will be done when you go to the next step.">
                  <InfoCircleFilled className="ml-2" />
                </Tooltip>
              </Radio>
              <Radio value={OPTION.CREATE_NEW}>
                Create new template based on current mapping
              </Radio>
            </Space>
          </Radio.Group>
        )}

        {process.mappings.size > 0 &&
          (!process.selectedTemplate ||
            optionOnUpdate === OPTION.CREATE_NEW) && (
            <Space direction="vertical" className="mt-5">
              <Typography.Text className="font-semibold text-red-500">
                <ExclamationCircleFilled className="mr-3" />
                You need to save current layout as a template.
              </Typography.Text>
              <Form form={form} layout="vertical" className="mt-5">
                <FormItem label="Template name" name="templateName">
                  <Input className="max-w-[200px]" />
                </FormItem>
              </Form>
            </Space>
          )}

        <StepsNavigation onNext={() => _onNext()} onBack={() => onPrev()} />
      </div>
    </StepWrapper>
  );
};
