import { Row, Space, Typography } from "antd";

type Props = {
  children: JSX.Element;
  title: string;
  subtitle?: string;
};
export const AuthWrapper = ({ children, title, subtitle }: Props) => {
  return (
    <div className="w-full grid grid-cols-2">
      <div className="col-span-1 bg-[#1a1c20]">
        <div className="flex h-screen items-center justify-center flex-col">
          <Row>
            <div className="flex flex-col pl-3">
              <span className="text-[24px] font-semibold">
                <a href="/auth/login" className="text-white">
                  OneCred
                </a>
              </span>
            </div>
          </Row>
        </div>
      </div>
      <div className="col-span-1">
        <div className="flex h-screen justify-center items-center">
          <div className="w-1/2">
            <Space direction="vertical" className="pb-12">
              <Typography className="font-semibold text-3xl">
                {title}
              </Typography>
              <Typography>{subtitle}</Typography>
            </Space>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
