import React from "react";
import { AppContextProvider } from "../../core/contexts/AppContextProvider";
import styled from "styled-components";
import { Header } from "./Header";
import { SearchBar } from "./SearchBar";
import { isPathNameKey } from "../../core/utils/search-bar.utils";
import { useLocation } from "react-router-dom";
import { SideBar } from "./Sidebar";

interface MainProps {
  children: JSX.Element;
  user: any;
}

export const Main = ({ children, user }: MainProps) => {
  const location = useLocation();

  return (
    <div className="flex flex-col relative min-h-screen">
      <AppContextProvider user={user}>
        <Header user={user} />

        <div className="flex h-heightMain">
          <SideBar />
          {isPathNameKey(location.pathname) && <SearchBar />}
          <MainBody>{React.cloneElement(children, { user })}</MainBody>
        </div>
      </AppContextProvider>
    </div>
  );
};

const MainBody = styled.div`
  position: relative;
  flex: 1 1 0%;
  padding-top: 24px;
  padding-inline: 46px;
  overflow-y: scroll;
  background-color: #fafafa;
`;
