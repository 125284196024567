import dayjs from "dayjs";

const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

const toUnix = (date: string | Date = new Date()) => dayjs(date).unix();
const formatDate = (date: number, format = "DD-MM-YYYY") =>
  dayjs.unix(date).format(format);

const dateUtils = {
  toUnix,
  formatDate,
};

export default dateUtils;
