import { RcFile } from "antd/es/upload";
import { ProviderDataModel } from "../../../core/types/provider";

export interface StepData {
  title: string;
  content: JSX.Element;
}

export type RosterColumnType = {
  dataIndex: string;
  uuid: string;
  field?: string;
};

export type RosterResponse = {
  provider: ProviderDataModel;
  location: Location;
};

export type ProcessDetails = {
  loading: boolean;
  rosterIndex?: number;
  sheetIndex: number;
  sheets: string[];
  tableCols: RosterColumnType[];
  tableRows: string[][];
  isSaveTemplate: boolean;
  isSaveGroup: boolean;
  currentStep: number;
  uploadedFile?: RcFile;
  selectedGroup?: string;
  selectedTemplate?: string;
  errorMessage: string;
  mappings: Map<any, any>;
};

export const initialProcess: ProcessDetails = {
  loading: false,
  rosterIndex: undefined,
  sheetIndex: 0,
  sheets: [],
  tableCols: [],
  tableRows: [],
  isSaveTemplate: false,
  isSaveGroup: false,
  currentStep: 0,
  uploadedFile: undefined,
  selectedGroup: "",
  selectedTemplate: "",
  errorMessage: "",
  mappings: new Map(),
};
