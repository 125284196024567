import { message } from "antd";
import api from ".";
import { LoginProps } from "../../types/auth";

const currentUser = async () => {
  return await api.get("/users/current");
};

export type LoginResponseType = {
  accessToken: string;
  refreshToken: string;
  idToken: string;
};

const login = async (props: LoginProps) => {
  return await api.post<LoginResponseType>("/auth/login", props);
};

const logout = async () => {
  return await api
    .get("/auth/logout")
    .then(() => {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      window.location.href = "/auth/login";
    })
    .catch(() => {
      message.error("Try again later.");
    });
};

export const auth = { currentUser, login, logout };
