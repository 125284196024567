import { ReloadOutlined } from "@ant-design/icons";
import { Space, Empty, Row, Button } from "antd";

interface Props {
  refetch: () => void;
}

export const NoProviderDataBanner = ({ refetch }: Props) => {
  return (
    <Space
      direction="vertical"
      className="w-full p-5 pt-12 justify-center text-center"
    >
      <Empty
        className="mt-12"
        description="Provider data is missing. Import the CAQH file."
      />

      <Row className="w-full justify-center gap-x-5 pt-3">
        <Button
          icon={<ReloadOutlined />}
          type="primary"
          onClick={() => refetch()}
        >
          Refresh
        </Button>
      </Row>
    </Space>
  );
};
